import { ChangeEvent, useState } from 'react';

import { Modal, Typography, TextField } from '@mui/material';
import { useNotify, useRecordContext, useRefresh } from 'react-admin';

import { OrdersRefundChargebackBox } from './OrdersRefundChargebackBox';
import { OrdersRefundChargebackConfirmButton } from './OrdersRefundChargebackConfirmButton';
import { OrdersRefundChargebackOpenButton } from './OrdersRefundChargebackOpenButton';
import { orderChargeback, orderRefund } from '../../../config/requests';
import { ResponseError } from '../../../types';

const OrdersRefundChargeback = (): JSX.Element => {
  const { availableRefundAmount, originId, allowRefund, allowChargeback } = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [openChargebackModal, setOpenChargebackModal] = useState(false);
  const [refundAmount, setRefundAmount] = useState<string | null>(null);
  const [refundError, setRefundError] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const modalTitle = openRefundModal
    ? `Refund, доступная сумма: ${availableRefundAmount}`
    : `Подтвердите Chargeback заказа: ${originId}`;

  const handleCloseModal = (): void => {
    setOpenRefundModal(false);
    setOpenChargebackModal(false);
  };

  const handleRefundAmountChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value },
    } = event;
    setRefundError('');
    if (Number(value) > availableRefundAmount) {
      setRefundError(`Максимальное значение ${availableRefundAmount}`);
    }
    setRefundAmount(value);
  };

  const handleChargeback = (): void => {
    handleCloseModal();
    orderChargeback(originId)
      .then(() => {
        notify('Chargeback выполнен', { type: 'success' });
        refresh();
      })
      .catch((response) =>
        setErrors(response.data?.errors?.map((error: ResponseError) => error.title) || []),
      );
  };

  const handleRefund = (): void => {
    if (!refundError) {
      if (refundAmount) {
        handleCloseModal();
        orderRefund(originId, Number(refundAmount))
          .then(() => {
            notify('Refund выполнен', { type: 'success' });
            refresh();
          })
          .catch((response) =>
            setErrors(response.data?.errors?.map((error: ResponseError) => error.title) || []),
          );
      } else {
        setRefundError('Обязательное поле');
      }
    }
  };

  return (
    <>
      <Modal onClose={handleCloseModal} open={openRefundModal || openChargebackModal}>
        <OrdersRefundChargebackBox>
          <Typography component="h2" fontWeight={500} mb={3} variant="h6">
            {modalTitle}
          </Typography>
          {openRefundModal && (
            <TextField
              error={!!refundError}
              helperText={refundError || ' '}
              label="Введите сумму возврата"
              onChange={handleRefundAmountChange}
              sx={{ marginBottom: 2, marginTop: 0 }}
              type="number"
              value={refundAmount}
            />
          )}
          <div>
            <OrdersRefundChargebackConfirmButton
              color="error"
              onClick={handleCloseModal}
              variant="contained"
            >
              Отмена
            </OrdersRefundChargebackConfirmButton>
            <OrdersRefundChargebackConfirmButton
              color="success"
              onClick={openRefundModal ? handleRefund : handleChargeback}
              variant="contained"
            >
              Подтвердить
            </OrdersRefundChargebackConfirmButton>
          </div>
        </OrdersRefundChargebackBox>
      </Modal>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <OrdersRefundChargebackOpenButton
          disabled={!allowRefund}
          onClick={() => {
            setOpenRefundModal(true);
            setErrors([]);
          }}
          variant="contained"
        >
          Refund
        </OrdersRefundChargebackOpenButton>
        <OrdersRefundChargebackOpenButton
          color="success"
          disabled={!allowChargeback}
          onClick={() => {
            setOpenChargebackModal(true);
            setErrors([]);
          }}
          variant="contained"
        >
          Chargeback
        </OrdersRefundChargebackOpenButton>

        {errors?.map((error, index) => (
          <Typography color="error" key={index} mt={2} width="100%">
            {error}
          </Typography>
        ))}
      </div>
    </>
  );
};

export default OrdersRefundChargeback;
