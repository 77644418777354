import React from 'react';

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { useGetList, useRefresh } from 'react-admin';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';

import { updateDispute } from '../../../../config/requests';
import { Order, OrderDispute } from '../../../../types';
import { AppDialogActions, AppDialogBase, AppDialogHeader } from '../../../Common/Dialog';

type Props = {
  order: Order | undefined;
  dispute: OrderDispute | undefined;
  onClose: () => void;
} & DialogProps;

const OrdersDisputeEditDialog = ({ order, dispute, onClose, open }: Props): JSX.Element => {
  const { data: orderDisputeStatuses } = useGetList('order_dispute_statuses');
  const form = useForm();
  const refresh = useRefresh();

  if (!order || !dispute) {
    return <></>;
  }

  const handleSubmit = () => {
    updateDispute(dispute.id, {
      ...form.getValues(),
      closedAt: form.getValues('closedAt') / 1000,
    }).then(() => {
      refresh();
      onClose();
    });
  };

  return (
    <AppDialogBase onClose={onClose} open={open}>
      <form {...form}>
        <AppDialogHeader onClose={onClose} title="Закрытие диспута" />
        <Typography mb={2}>
          Диспут по заказу #{order?.originId || ''} будет закрыт с выбранным статусом.
        </Typography>
        <Controller
          control={form.control}
          name="closedAt"
          render={({ field, fieldState }) => (
            <DatePicker
              customInput={
                <TextField
                  InputLabelProps={{ shrink: true }}
                  error={!!fieldState.error}
                  fullWidth
                  helperText={fieldState.error?.message}
                  label="Дата"
                  size="small"
                  value={field.value}
                  variant="outlined"
                />
              }
              dateFormat="dd/MM/yyyy"
              locale="ru"
              onChange={(date) => {
                field.onChange(date?.getTime());
              }}
              selected={field.value}
              selectsStart
              startDate={field.value}
            />
          )}
          rules={{
            required: 'Значение не должно быть пустым',
          }}
        />
        <Controller
          control={form.control}
          name="statusId"
          render={({ field, fieldState }) => (
            <FormControl error={!!fieldState.error} fullWidth>
              <InputLabel
                shrink
                sx={{
                  backgroundColor: '#fff',
                  padding: '0 5px',
                  top: '-12px',
                  left: '-2px',
                }}
              >
                Статус
              </InputLabel>
              <Select
                fullWidth
                label="Статус"
                onChange={(value) => {
                  field.onChange(value);
                }}
                size="small"
                value={field.value}
                variant="outlined"
              >
                {orderDisputeStatuses?.map((status) => {
                  if (status.alias === 'received') {
                    return;
                  }
                  return (
                    <MenuItem key={status.id} value={status.originId}>
                      {status.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {!!fieldState.error && <FormHelperText>{fieldState.error?.message}</FormHelperText>}
            </FormControl>
          )}
          rules={{
            required: 'Значение не должно быть пустым',
          }}
        />
        <Controller
          control={form.control}
          name="closeReason"
          render={({ field, fieldState }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              error={!!fieldState.error}
              fullWidth
              helperText={fieldState.error?.message}
              label="Причина"
              multiline
              onChange={(value) => {
                field.onChange(value);
              }}
              rows={4}
              size="small"
              value={field.value}
              variant="outlined"
            />
          )}
          rules={{
            required: 'Значение не должно быть пустым',
            maxLength: {
              value: 255,
              message: 'Значение слишком длинное. Должно быть равно 255 символам или меньше.',
            },
          }}
        />
        <AppDialogActions>
          <Box display="flex" gap={2}>
            <Button onClick={form.handleSubmit(handleSubmit)} variant="contained">
              Подтвердить
            </Button>
            <Button onClick={onClose} variant="outlined">
              Отменить
            </Button>
          </Box>
        </AppDialogActions>
      </form>
    </AppDialogBase>
  );
};

export default OrdersDisputeEditDialog;
