import { useState } from 'react';

import ContentAdd from '@mui/icons-material/Add';
import { useRecordModal } from 'hooks/useRecordModal';
import { Identifier, WithListContext } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { Button } from 'shared/mui/Buttons';
import { DeleteIconButton, EditIconButton } from 'shared/mui/IconButtons';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { Typography } from 'shared/mui/Typography';
import { ListPage } from 'shared/react-admin/Pages';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { ApiClientWhiteIp, Permission } from 'types';

import { StyledDatagrid } from './ApiClientsShowWhiteIpAddresses.styled';
import { ApiClientsShowWhiteIpAddressesCreateDialog } from './ApiClientsShowWhiteIpAddressesCreateDialog/ApiClientsShowWhiteIpAddressesCreateDialog';
import { ApiClientsShowWhiteIpAddressesDeleteDialog } from './ApiClientsShowWhiteIpAddressesDeleteDialog/ApiClientsShowWhiteIpAddressesDeleteDialog';
import { ApiClientsShowWhiteIpAddressesEditDialog } from './ApiClientsShowWhiteIpAddressesEditDialog/ApiClientsShowWhiteIpAddressesEditDialog';
import { usePermissions } from '../../../../hooks/usePermissions';
import { DateTimeField, TextField } from '../../../../shared/react-admin/Fields';

type Props = {
  apiClientId?: Identifier;
};

export const ApiClientsShowWhiteIpAddresses: React.FC<Props> = ({ apiClientId }) => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const {
    record: recordEditDialog,
    handleCloseRecordModal: handleCloseEditDialog,
    openRecordModal: openEditDialog,
    handleOpenRecordModal: handleOpenEditDialog,
  } = useRecordModal<ApiClientWhiteIp>();
  const {
    record: recordDeleteDialog,
    handleCloseRecordModal: handleCloseDeleteDialog,
    openRecordModal: openDeleteDialog,
    handleOpenRecordModal: handleOpenDeleteDialog,
  } = useRecordModal<ApiClientWhiteIp>();
  return (
    <ListPage
      actions={[
        hasResourcePermissions('api_client_white_ip_addresses', [
          Permission.Full,
          Permission.Create,
        ]) && (
          <Button
            key="create"
            label="Добавить"
            onClick={() => setOpenCreateDialog(true)}
            startIcon={<ContentAdd />}
            sx={{ marginTop: '8px', padding: '3px 16px' }}
            variant="contained"
          />
        ),
      ]}
      empty={false}
      filter={{ apiClient: apiClientId }}
      headerTitle={{
        titleText: 'Список белых IP адресов',
        titleSize: 'small',
      }}
      resource="api_client_white_ip_addresses"
      sort={{ field: 'id', order: 'DESC' }}
      sx={{ marginTop: 0, marginBottom: 2 }}
    >
      <WithListContext
        render={({ data, isLoading }) => {
          const showAlert = !data?.length && !isLoading;
          if (showAlert) {
            return (
              <Alert severity="info">
                <Typography fontWeight={500}>Здесь пока нет белых IP адресов.</Typography>
                <Typography>Чтобы добавить белые IP адреса, нажмите кнопку “Добавить”.</Typography>
              </Alert>
            );
          }
          return (
            <>
              <StyledDatagrid bulkActionButtons={false}>
                <TextField
                  component="div"
                  label="ID"
                  sortBy="id"
                  source="originId"
                  sx={{ marginTop: '2px' }}
                />
                <TextField label="IP адрес:" source="ip" />
                <TextField label="Маска:" source="mask" />
                <DateTimeField label="Дата создания" source="createdAt" />
                <DateTimeField label="Дата редактир." source="updatedAt" />
                <ActionsSlots
                  render={(record: ApiClientWhiteIp) => (
                    <>
                      {hasResourceAccess('user_actions') && (
                        <UserActionsIconButton
                          entityName="api_client_white_ip_address"
                          target="_blank"
                        />
                      )}
                      {hasResourcePermissions('api_client_white_ip_addresses', [
                        Permission.Full,
                        Permission.Update,
                      ]) && <EditIconButton onClick={() => handleOpenEditDialog(record)} />}
                      {hasResourcePermissions('api_client_white_ip_addresses', [
                        Permission.Full,
                        Permission.Delete,
                      ]) && <DeleteIconButton onClick={() => handleOpenDeleteDialog(record)} />}
                    </>
                  )}
                  sx={{ marginTop: '-6px' }}
                />
              </StyledDatagrid>
            </>
          );
        }}
      />
      <ApiClientsShowWhiteIpAddressesCreateDialog
        apiClient={apiClientId}
        onClose={() => setOpenCreateDialog(false)}
        open={openCreateDialog}
      />
      <ApiClientsShowWhiteIpAddressesEditDialog
        apiClient={apiClientId}
        onClose={handleCloseEditDialog}
        open={openEditDialog}
        record={recordEditDialog}
      />
      <ApiClientsShowWhiteIpAddressesDeleteDialog
        onClose={handleCloseDeleteDialog}
        open={openDeleteDialog}
        record={recordDeleteDialog}
      />
    </ListPage>
  );
};
