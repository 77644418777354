import { DeleteStages } from 'constants/deleteStages';

import { Code } from '@mui/icons-material';
import { blue } from '@mui/material/colors';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useStagedDelete } from 'hooks/useStagedDelete';
import { RaRecord, useRefresh } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { CancelButton, CreateButton } from 'shared/mui/Buttons';
import { DeleteStageDialog } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialog';
import { DeleteStageDialogDenyReason } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogDenyReason';
import {
  DeleteIconButton,
  EditLinkIconButton,
  ShowLinkIconButton,
  UserEntityPermissionsIconButton,
} from 'shared/mui/IconButtons';
import { IconLabel } from 'shared/mui/Labels/IconLabel/IconLabel';
import { ListButton } from 'shared/react-admin/Buttons';
import {
  ChipField,
  DateTimeField,
  FunctionField,
  GatewayStatusIconField,
  SingleFieldList,
  TextField,
  TooltipTextField,
} from 'shared/react-admin/Fields';
import { ListPage } from 'shared/react-admin/Pages';
import {
  ReferenceArrayField,
  ReferenceField,
  ReferenceOneField,
} from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { ProviderShowDialog } from 'shared/widgets/ProviderShowDialog/ProviderShowDialog';
import { UserEntityPermissionsNames } from 'shared/widgets/UserEntityPermissionsDialog/constants/userEntityPermissionsNames';
import { UserEntityPermissionsDialog } from 'shared/widgets/UserEntityPermissionsDialog/UserEntityPermissionsDialog';
import { Gateway, Permission, Provider } from 'types';

import { StyledChip, StyledChipField, StyledDatagrid } from './GatewaysList.styled';
import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { GatewaysFilters } from '../GatewaysFilters';

export const GatewaysList: React.FC = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();
  const { record, handleOpenRecordModal, openRecordModal, handleCloseRecordModal } =
    useRecordModal<Gateway>();
  const {
    record: provider,
    handleCloseRecordModal: handleCloseRecordModalProvider,
    openRecordModal: openRecordModalProvider,
    handleOpenRecordModal: handleOpenRecordModalProvider,
  } = useRecordModal<Provider>();

  const {
    record: gateway,
    handleCloseRecordModal: handleCloseRecordModalPermissions,
    openRecordModal: openRecordModalPermissions,
    handleOpenRecordModal: handleOpenRecordModalPermissions,
  } = useRecordModal<Gateway>();

  const refresh = useRefresh();

  const { deleteOneRecord, denyDeleteReason, stage, setStage } = useStagedDelete({
    record,
    onSuccess: () => {
      refresh();
      handleCloseRecordModal();
      setStage(DeleteStages.Initial);
    },
  });

  const handleCloseDeleteDialog = () => {
    setStage(DeleteStages.Initial);
    handleCloseRecordModal();
  };

  return (
    <ListPage
      actions={[
        hasResourcePermissions('gateways', [Permission.Full, Permission.Create]) && (
          <CreateButton key="create_gateways" />
        ),
      ]}
      empty={false}
      headerTitle={{
        titleText: 'Шлюзы',
        tooltipText:
          'Технический канал для проведения платежей с помощью одного или нескольких методов, предоставляемых провайдером. Шлюз - это папка с кодом, отвечающим за взаимодействие с API провайдера для проведения платежей с помощью его методов и связанная с ней через алиас шлюза запись в таблице. При интеграции с провайдером для каждого предоставляемого им метода нужно создавать шлюз. Например, существует провайдер PagSmile, которые предоставляет методы Boleto и PSE. При интеграции с PagSmile для каждого метода нужно создать в административной панели шлюзы PagSmile Boleto и PagSmile PSE и реализовать код для взаимодействия с API PagSmile для каждого метода.',
      }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <GatewaysFilters />
      <StyledDatagrid bulkActionButtons={false}>
        <TextField label="ID" sortBy="id" source="originId" />
        <TooltipTextField label="Название" source="name" />
        <TooltipTextField label="Алиас" source="alias" />
        <GatewayStatusIconField label="Статус" sx={{ display: 'flex', minWidth: '100px' }} />
        {hasResourceAccess('providers') && (
          <ReferenceOneField
            label="Провайдер"
            reference="providers"
            sortBy="provider.name"
            source="provider"
            target="id"
          >
            <FunctionField
              render={(record: Provider | undefined) => (
                <TextField
                  onClick={() => handleOpenRecordModalProvider(record)}
                  source="name"
                  sx={{
                    cursor: 'pointer',
                    color: blue[700],
                  }}
                />
              )}
            />
          </ReferenceOneField>
        )}
        <ReferenceField
          emptyText="—"
          label="Направление"
          reference="gateway_directions"
          sortable={false}
          source="direction"
        >
          <FunctionField
            render={(record: RaRecord) =>
              record.description === 'Все направления' ? (
                <Box>
                  <StyledChip label="Пополнение" />
                  <StyledChip label="Вывод" />
                </Box>
              ) : (
                <StyledChip label={record.description} />
              )
            }
          />
        </ReferenceField>
        {hasResourceAccess('gateway_methods') && (
          <ReferenceArrayField
            label="Методы"
            reference="gateway_methods"
            sortable={false}
            source="gatewayMethods"
          >
            <SingleFieldList linkType={false} sx={{ flexDirection: 'column' }}>
              <StyledChipField source="methodName" />
            </SingleFieldList>
          </ReferenceArrayField>
        )}
        {hasResourceAccess('currencies') && (
          <ReferenceOneField
            label="Валюта по умолчанию"
            link={false}
            reference="currencies"
            sortBy="defaultCurrency.alphaCode"
            source="defaultCurrency"
            target="id"
          >
            <TextField source="alphaCode" />
          </ReferenceOneField>
        )}
        {hasResourceAccess('currencies') && (
          <ReferenceArrayField
            label="Поддерживаемые валюты"
            reference="currencies"
            sortable={false}
            source="supportedCurrencies"
          >
            <SingleFieldList linkType={false}>
              <ChipField source="alphaCode" />
            </SingleFieldList>
          </ReferenceArrayField>
        )}
        {hasResourceAccess('limits') && (
          <FunctionField
            label={<IconLabel icon={Code} label="Лимиты" />}
            render={(record: Gateway) => {
              return (
                <ListButton
                  filter={{
                    gateway: record?.id,
                  }}
                  icon={<Code />}
                  label="Перейти"
                  pathname="/limits"
                />
              );
            }}
            sx={{ verticalAlign: 'top!important' }}
          />
        )}
        <DateTimeField label="Дата создания" source="createdAt" />
        <DateTimeField label="Дата редактир." source="updatedAt" />
        <ActionsSlots
          render={(record: Gateway) => (
            <>
              {hasResourceAccess('user_entity_permissions') && (
                <UserEntityPermissionsIconButton
                  onClick={() => handleOpenRecordModalPermissions(record)}
                />
              )}
              {hasResourceAccess('user_actions') && <UserActionsIconButton entityName="gateway" />}
              <ShowLinkIconButton />
              {hasResourcePermissions('gateways', [Permission.Full, Permission.Update]) &&
                !record.locked && <EditLinkIconButton />}
              {hasResourcePermissions('gateways', [Permission.Full, Permission.Delete]) && (
                <DeleteIconButton onClick={() => handleOpenRecordModal(record)} />
              )}
            </>
          )}
        />
      </StyledDatagrid>
      <DeleteStageDialog
        deleteOneRecord={deleteOneRecord}
        details={`Шлюз ${record?.name} будет удален без возможности восстановления.`}
        onClose={handleCloseDeleteDialog}
        open={openRecordModal}
        stage={stage}
        title="Удаление шлюза"
      >
        <DeleteStageDialogDenyReason reason={denyDeleteReason}>
          <CancelButton
            key="history-back-button"
            label="Вернуться к списку шлюзов"
            onClick={handleCloseDeleteDialog}
            variant="outlined"
          />
        </DeleteStageDialogDenyReason>
      </DeleteStageDialog>
      {gateway && openRecordModalPermissions && (
        <UserEntityPermissionsDialog
          entityName={UserEntityPermissionsNames.Gateway}
          onClose={handleCloseRecordModalPermissions}
          open={openRecordModalPermissions}
          record={gateway}
          titleCreate="Включение доступа к шлюзу"
          titleEdit="Редактирование доступа к шлюзу"
        />
      )}
      {provider && (
        <ProviderShowDialog
          onClose={handleCloseRecordModalProvider}
          open={openRecordModalProvider}
          provider={provider}
        />
      )}
    </ListPage>
  );
};
