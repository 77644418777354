import { queryClient } from 'App';
import { useDelete } from 'react-admin';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { ApiClientWhiteIp } from 'types';

type Props = {
  onClose: VoidFunction;
  open: boolean;
  record?: ApiClientWhiteIp;
};

export const ApiClientsShowWhiteIpAddressesDeleteDialog: React.FC<Props> = ({
  onClose,
  open,
  record,
}) => {
  const [deleteOne, { isLoading }] = useDelete(
    'api_client_white_ip_addresses',
    {
      id: record?.id || '',
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['api_client_white_ip_addresses']);
        onClose();
      },
    },
  );

  return (
    <DeleteDialog
      details={`После удаления IP адреса ${record?.ip} будет недоступен для восстановления.`}
      isLoading={isLoading}
      onClose={onClose}
      onDelete={deleteOne}
      onSuccess={onClose}
      open={open}
      title="Удаление IP адреса"
    />
  );
};
