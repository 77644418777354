import { DynamicFeed } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { ExportFormatsButton } from 'shared/mui/Buttons/ExportFormatsButton/ExportFormatsButton';
import { Link } from 'shared/react-admin/Link/Link';
import { ExportFormat, Permission } from 'types';

import { usePermissions } from '../../../hooks/usePermissions';

type OrdersListActionsProps = {
  exportFormat: ExportFormat;
  setExportFormat: (exportFormat: ExportFormat) => void;
  onExportFormatsButtonClick: () => void;
};

export const OrdersListActions = ({
  exportFormat,
  setExportFormat,
  onExportFormatsButtonClick,
}: OrdersListActionsProps): JSX.Element => {
  const { hasResourcePermissions } = usePermissions();

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
      }}
    >
      <ExportFormatsButton
        exportFormat={exportFormat}
        key="export-button"
        onChangeFormat={setExportFormat}
        onClick={onExportFormatsButtonClick}
      />
      {hasResourcePermissions('orders/bulk_update', [Permission.Update, Permission.Full]) && (
        <Button
          color="primary"
          component={Link}
          startIcon={<DynamicFeed />}
          sx={{ padding: '5px 16px' }}
          target="_blank"
          to="/orders_bulk_update"
          variant="outlined"
        >
          Массовое изменение
        </Button>
      )}
    </Box>
  );
};
