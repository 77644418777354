import { DeleteStages } from 'constants/deleteStages';

import KeyIcon from '@mui/icons-material/Key';
import { useFlag } from '@unleash/proxy-client-react';
import { queryClient } from 'App';
import { useApiContext } from 'context/apiContext';
import { useStagedDelete } from 'hooks/useStagedDelete';
import { Alert } from 'shared/mui/Alert/Alert';
import { CancelButton, CreateButton } from 'shared/mui/Buttons';
import { ArchiveConditionListChip } from 'shared/mui/Chips/ArchiveConditionListChip/ArchiveConditionListChip';
import { DeleteStageDialog } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialog';
import { DeleteStageDialogDenyReason } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogDenyReason';
import { DeleteIconButton, EditLinkIconButton, ShowLinkIconButton } from 'shared/mui/IconButtons';
import { ArchiveIconButton } from 'shared/mui/IconButtons/ArchiveIconButton/ArchiveIconButton';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import {
  ChipField,
  DateTimeField,
  FunctionField,
  SingleFieldList,
  TextField,
  TooltipTextField,
} from 'shared/react-admin/Fields';
import { ListPage } from 'shared/react-admin/Pages';
import { ReferenceArrayField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { CompanyShowDialog } from 'shared/widgets/CompanyShowDialog/CompanyShowDialog';

import { StyledDatagrid } from './MerchantsList.styled';
import { MerchantsListFilter } from './MerchantsListFilter';
import { usePermissions } from '../../../hooks/usePermissions';
import { useRecordModal } from '../../../hooks/useRecordModal';
import { Company, Merchant, Permission } from '../../../types';
import { MerchantsArchiveDialog } from '../components/MerchantsArchiveDialog/MerchantsArchiveDialog';
import { MerchantsUnarchiveDialog } from '../components/MerchantsUnarchiveDialog/MerchantsUnarchiveDialog';
import { NotificationKeyAccessDenied } from '../components/NotificationKeyAccessDenied/NotificationKeyAccessDenied';
import { NotificationKeyTooltip } from '../components/NotificationKeyTooltip/NotificationKeyTooltip';

export const MerchantsList: React.FC = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();
  const { record, handleCloseRecordModal, openRecordModal, handleOpenRecordModal } =
    useRecordModal<Company>();
  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Merchant>();

  const {
    record: recordUnarchive,
    handleOpenRecordModal: handleOpenRecordModalUnarchive,
    openRecordModal: openRecordModalUnarchive,
    handleCloseRecordModal: handleCloseRecordModalUnarchive,
  } = useRecordModal<Merchant>();
  const {
    record: recordArchive,
    handleOpenRecordModal: handleOpenRecordModalArchive,
    openRecordModal: openRecordModalArchive,
    handleCloseRecordModal: handleCloseRecordModalArchive,
  } = useRecordModal<Merchant>();

  const { deleteOneRecord, denyDeleteReason, stage, setStage } = useStagedDelete({
    record: recordDelete,
    onSuccess: async () => {
      await queryClient.refetchQueries(['merchants']);
      handleCloseRecordModalDelete();
      setStage(DeleteStages.Initial);
    },
  });

  const handleCloseDeleteDialog = () => {
    setStage(DeleteStages.Initial);
    handleCloseRecordModalDelete();
  };

  const { user } = useApiContext();
  const isMerchantAccessesEnabled = useFlag('wppa-6347-merchant-accesses');

  return (
    <ListPage
      actions={[
        hasResourcePermissions('merchants', [Permission.Full, Permission.Create]) && (
          <CreateButton key="create-merchants" />
        ),
      ]}
      empty={false}
      headerTitle={{
        titleText: 'Мерчанты',
        tooltipText: 'Учетная запись клиента сервиса Kubera.',
      }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <MerchantsListFilter />
      {isMerchantAccessesEnabled && user.showAlertEmptyAccess ? (
        <Alert severity="warning">{`Для пользователя ${user.firstName} ${user.lastName} нет доступных мерчантов, обратитесь к администратору системы настроек доступа`}</Alert>
      ) : (
        <StyledDatagrid bulkActionButtons={false}>
          <TextField label="ID" sortBy="id" source="originId" />
          <TooltipTextField label="Название" source="name" />
          <TooltipTextField label="Алиас" source="alias" />
          <FunctionField
            label="Состояние"
            render={({ archive }: Merchant) => <ArchiveConditionListChip archive={archive} />}
            sortable={false}
            source="archive"
          />
          {hasResourceAccess('companies') && (
            <ReferenceArrayField
              label="Компании"
              reference="companies"
              sortable={false}
              source="companies"
            >
              <SingleFieldList linkType={false}>
                <FunctionField
                  render={(record?: Company) => (
                    <ChipField
                      color="primary"
                      onClick={() => handleOpenRecordModal(record)}
                      record={record}
                      source="name"
                      sx={{
                        '.MuiChip-label': { color: '#FFF', cursor: 'pointer', lineHeight: '12px' },
                      }}
                    />
                  )}
                />
              </SingleFieldList>
            </ReferenceArrayField>
          )}
          <FunctionField
            label="Ключ нотификации"
            render={({ notificationKey }: Merchant) =>
              notificationKey ? (
                <NotificationKeyTooltip icon={<KeyIcon />} value={notificationKey} />
              ) : (
                <NotificationKeyAccessDenied />
              )
            }
            sortable={false}
            source="notificationKey"
          />
          <DateTimeField label="Дата создания" source="createdAt" />
          <DateTimeField label="Дата редактир." source="updatedAt" />
          <ActionsSlots
            render={(record: Merchant) => (
              <>
                {hasResourceAccess('user_actions') && (
                  <UserActionsIconButton entityName="merchant" />
                )}
                <ShowLinkIconButton />
                {hasResourcePermissions('merchants', [Permission.Full, Permission.Update]) &&
                  (record.archive ? (
                    <ArchiveIconButton
                      isRotate
                      onClick={() => handleOpenRecordModalUnarchive(record)}
                    />
                  ) : (
                    <EditLinkIconButton />
                  ))}
                {hasResourcePermissions('merchants', [Permission.Full, Permission.Delete]) &&
                  (record.archive ? (
                    <DeleteIconButton onClick={() => handleOpenRecordModalDelete(record)} />
                  ) : (
                    <ArchiveIconButton onClick={() => handleOpenRecordModalArchive(record)} />
                  ))}
              </>
            )}
          />
        </StyledDatagrid>
      )}
      {openRecordModalDelete && (
        <DeleteStageDialog
          deleteOneRecord={deleteOneRecord}
          details={`Мерчант ${recordDelete?.name || ''} будет удален без возможности восстановления.`}
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          stage={stage}
          title="Удаление мерчанта"
        >
          <DeleteStageDialogDenyReason reason={denyDeleteReason}>
            <CancelButton
              key="history-back-button"
              label="Вернуться к списку мерчантов"
              onClick={handleCloseDeleteDialog}
              variant="outlined"
            />
          </DeleteStageDialogDenyReason>
        </DeleteStageDialog>
      )}
      {openRecordModalUnarchive && recordUnarchive && (
        <MerchantsUnarchiveDialog
          onClose={handleCloseRecordModalUnarchive}
          open={openRecordModalUnarchive}
          record={recordUnarchive}
        />
      )}
      {openRecordModalArchive && recordArchive && (
        <MerchantsArchiveDialog
          onClose={handleCloseRecordModalArchive}
          open={openRecordModalArchive}
          record={recordArchive}
        />
      )}
      {record && (
        <CompanyShowDialog
          company={record}
          onClose={handleCloseRecordModal}
          open={openRecordModal}
        />
      )}
    </ListPage>
  );
};
