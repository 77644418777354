import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { queryClient } from 'App';
import { usePermissions } from 'hooks/usePermissions';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { SaveButton } from 'shared/react-admin/Buttons';
import { Permission } from 'types';
import { GatewayConversionListItem } from 'types/orderConversion';
import { convertReactAdminIdToHydraId } from 'utils';
import * as yup from 'yup';

import { OrderConversionRequestParams } from '../../../../../../config/requests';
import { useCreateGatewayConversionSettings } from '../../hooks/useCreateGatewayConversionSettings';
import { GatewaySettingsForm } from '../components/GatewaySettingsForm/GatewaySettingsForm';
import { deviationFieldSchema } from '../schemas/shema';

type Props = {
  record: GatewayConversionListItem | undefined;
  merchant: string | null;
  open: boolean;
  onClose: VoidFunction;
  params: OrderConversionRequestParams;
};

export type GatewaySettingsFormValues = {
  successDeviation: number;
  cancelDeviation: number;
  pendingDeviation: number;
  notify: boolean;
};

export const OrderConversionListGatewayCreate: React.FC<Props> = ({
  open,
  onClose,
  record,
  merchant,
  params,
}) => {
  const { hasResourcePermissions } = usePermissions();

  const form = useForm<GatewaySettingsFormValues>({
    resolver: yupResolver<any>(
      yup.object().shape({
        successDeviation: deviationFieldSchema,
        cancelDeviation: deviationFieldSchema,
        pendingDeviation: deviationFieldSchema,
      }),
    ),
    shouldUnregister: true,
  });

  const { createGatewayConversionSettings } = useCreateGatewayConversionSettings();
  async function onSubmit(values: GatewaySettingsFormValues) {
    if (!merchant || !record?.id) {
      return;
    }

    await createGatewayConversionSettings.mutateAsync({
      merchant,
      gateway: convertReactAdminIdToHydraId(record?.id, 'gateways'),
      successDeviation: values.successDeviation.toString(),
      cancelDeviation: values.cancelDeviation.toString(),
      pendingDeviation: values.pendingDeviation.toString(),
      notify: values.notify,
    });

    onClose();
    await queryClient.refetchQueries([
      'monitoring/order_conversion',
      params.period,
      params.merchantId,
      params?.detailed,
      params?.providerIds,
      params?.gatewayIds,
    ]);
  }

  return (
    <Dialog onClose={onClose} open={open} transitionDuration={0}>
      <CardHeader
        actions={<CloseIconButton key="close-button" onClose={onClose} />}
        sx={{ height: 'auto' }}
        title="Создание настроек по пороговым значениям мониторинга"
      />

      <Box sx={{ padding: '8px 24px' }}>
        <Alert severity="warning">
          В соответствии с установленными пороговыми значениями, будет определяться
          максимально-допустимое отклонение от среднестатистических показателей рассчитанных за 14
          дней по конкретному шлюзу.
        </Alert>
      </Box>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent>
            <GatewaySettingsForm />
          </CardContent>
          <CardActionsButtons
            leftActionsSlot={[
              hasResourcePermissions('gateway_conversion_settings', [
                Permission.Full,
                Permission.Create,
              ]) && (
                <SaveButton key="save-button" label="Создать" type="submit" variant="contained" />
              ),
              <Button key="close-button" label="Отменить" onClick={onClose} variant="outlined" />,
            ]}
          />
        </form>
      </FormProvider>
    </Dialog>
  );
};
