import qs from 'qs';
import { useLocation } from 'react-router-dom';
import {
  AutocompleteInputIntrospected,
  NumberInputIntrospected,
  TextInputIntrospected,
} from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { fieldWidth } from '../../themes/variables';
import { AppCreateGuesser } from '../Common/Create';

type ApiClientDefaultValueType = {
  apiClient: string;
};

const ApiClientWhiteIpAddressesCreate = (): JSX.Element => {
  const location = useLocation();
  const defaultValues = qs.parse(
    location?.search.replace('?', '') as string,
  ) as ApiClientDefaultValueType;

  return (
    <AppCreateGuesser
      actions={false}
      listName="Клиенты API – белые IP-адреса"
      mutationOptions={{ onError: () => undefined }}
      title="Создание белого IP адреса клиента API"
    >
      <ReferenceInput perPage={200} reference="api_clients" source="apiClient">
        <AutocompleteInputIntrospected
          defaultValue={defaultValues.apiClient}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          label="Клиент API"
          optionText="name"
          source="name"
          sx={fieldWidth.medium}
          variant="outlined"
        />
      </ReferenceInput>
      <TextInputIntrospected label="IP" source="ip" sx={fieldWidth.medium} variant="outlined" />
      <NumberInputIntrospected
        label="Маска"
        source="mask"
        sx={fieldWidth.medium}
        variant="outlined"
      />
    </AppCreateGuesser>
  );
};

export default ApiClientWhiteIpAddressesCreate;
