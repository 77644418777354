import { FilterChipType } from 'constants/filterChipType';

import { useEffect, useMemo } from 'react';

import { OrdersFilterGroupById } from 'components/Orders/OrdersFilter/OrdersFilterGroupById/OrdersFilterGroupById';
import { getUnixTime, subDays } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { RaRecord, useListContext } from 'react-admin';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import { FormProvider, Controller } from 'react-hook-form';
import { Divider } from 'shared/mui/Divider/Divider';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterCollapsible } from 'shared/mui/Filter/FilterCollapsible/FilterCollapsible';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { TextFieldShrink } from 'shared/mui/TextFieldShrink/TextFieldShrink';
import { AutocompleteInputShrink, EnumAutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { appColors } from 'themes/variables';
import { getIdsArray } from 'utils/getIdsArray';

import { OrdersFilterCalendarTodayIcon } from './OrdersFilterCalendarTodayIcon';
import { OrdersFilterCloseIcon } from './OrdersFilterCloseIcon';
import { DIGIT_REGEXP } from '../../../constants/regexp';
import { useFilterChips } from '../../../hooks/useFilterChips';
import { useFilters } from '../../../hooks/useFilters';
import { OrdersFilterFormValues } from '../../../types';
import { getDateRangeDefaultValues, getDateRangeFilter } from '../../../utils';

registerLocale('ru', ru);

type Props = {
  alert?: JSX.Element | undefined;
};

export const OrdersFilter: React.FC<Props> = ({ alert }) => {
  const { displayedFilters, filterValues, setFilters } = useListContext();
  const {
    resetFilterArrayById,
    resetFilterBySource,
    form,
    form: { watch },
  } = useFilters(filterValues, {
    ...filterValues,
    createdAt: getDateRangeDefaultValues(
      filterValues?.createdAt || {
        gte: getUnixTime(subDays(new Date(), 1)),
        lte: getUnixTime(new Date()),
      },
    ),
  });

  const onSubmit = (values: OrdersFilterFormValues) => {
    const idsArray = getIdsArray(values.id);
    const merchantReferenceIdsArray = getIdsArray(values.merchantReference);
    const providerReferenceIdsArray = getIdsArray(values.providerReferenceId);
    form.setValue('id', idsArray);
    form.setValue('merchantReference', merchantReferenceIdsArray);
    form.setValue('providerReferenceId', providerReferenceIdsArray);

    setFilters(
      {
        ...values,
        id: idsArray,
        merchantReference: merchantReferenceIdsArray,
        providerReferenceId: providerReferenceIdsArray,
        createdAt: getDateRangeFilter(values.createdAt),
      },
      displayedFilters,
    );
  };

  const resetFilters = () => {
    setFilters({}, displayedFilters);
    clearAmountGte();
    clearAmountLte();
    form.reset({
      id: null,
      merchantReference: null,
      providerReferenceId: null,
      transaction: '',
      merchant: '',
      gateway: [],
      method: [],
      status: [],
      statusNew: [],
      currency: [],
      direction: '',
      amount: {
        gte: '',
        lte: '',
      },
      createdAt: {
        gte: subDays(new Date(), 1),
        lte: new Date(),
      },
    });
  };

  const { filterChipValues } = useFilterChips({
    filters: [
      { source: 'id', label: 'ID', type: FilterChipType.String },
      { source: 'merchantReference', label: 'ID у мерчанта', type: FilterChipType.String },
      { source: 'providerReferenceId', label: 'ID у провайдера', type: FilterChipType.String },
      { source: 'transaction', label: 'ID транзакции', type: FilterChipType.String },
      { source: 'merchant', label: 'Мерчант', type: FilterChipType.Id },
      { source: 'status', label: 'Статус заказа', type: FilterChipType.IdArray },
      { source: 'statusNew', label: 'Статус заказа', type: FilterChipType.OrderStatus },
      { source: 'method', label: 'Метод', type: FilterChipType.IdArray },
      { source: 'currency', label: 'Валюта', type: FilterChipType.IdArray },
      { source: 'gateway', label: 'Шлюз', type: FilterChipType.IdArray },
      { source: 'direction', label: 'Направление', type: FilterChipType.Direction },
      { source: 'amount.gte', label: 'Сумма от', type: FilterChipType.String },
      { source: 'amount.lte', label: 'Сумма до', type: FilterChipType.String },
      { source: 'createdAt.gte', label: 'Период создания от', type: FilterChipType.DateInterval },
      { source: 'createdAt.lte', label: 'Период создания до', type: FilterChipType.DateInterval },
    ],
    appliedFilters: filterValues,
  });

  const formValues = watch();

  const isIdFiltersDisabled = useMemo(() => {
    const { id, merchantReference, providerReferenceId } = formValues;
    const isFormClear = Object.values(formValues).every(
      (formValue) => formValue instanceof Date || !formValue || !(formValue as []).length,
    );
    if (
      form.formState.isDirty &&
      !isFormClear &&
      !id &&
      !merchantReference &&
      !providerReferenceId
    ) {
      return true;
    }
    return false;
  }, [formValues]);

  const isRestFiltersDisabled = useMemo(() => {
    const { id, merchantReference, providerReferenceId } = formValues;
    if (form.formState.isDirty && (id || merchantReference || providerReferenceId)) {
      return true;
    }
    return false;
  }, [formValues]);

  const clearAmountGte = () => {
    form.setValue('amount.gte', '');
  };

  const clearAmountLte = () => {
    form.setValue('amount.lte', '');
  };

  useEffect(() => {
    if (isRestFiltersDisabled) {
      form.setValue('createdAt.gte', '');
      form.setValue('createdAt.lte', '');
    }
  }, [isIdFiltersDisabled, isRestFiltersDisabled]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterCollapsible
          actions={[
            <FilterApplyButton disabled={false} key="apply-button" type="submit" />,
            <FilterResetButton key="reset-button" onClick={resetFilters} />,
          ]}
          alert={alert}
          filterChipValues={filterChipValues}
          resetFilterArrayById={resetFilterArrayById}
          resetFilterBySource={resetFilterBySource}
        >
          <OrdersFilterGroupById
            isIdFiltersDisabled={isIdFiltersDisabled}
            resetFilters={resetFilters}
          />
          <Divider
            orientation="horizontal"
            sx={{ marginTop: 1, marginBottom: '12px', borderColor: appColors.divider }}
          />
          <FilterFieldGroup>
            <ReferenceInput isFilter={true} perPage={100} reference="merchants" source="merchant">
              <AutocompleteInputShrink
                disabled={isRestFiltersDisabled}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                fullWidth
                helperText={false}
                label="Мерчант"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceArrayInput
              isFilter={true}
              name="statusNew"
              perPage={1000}
              reference="new/order_statuses"
              source="new/order_statuses"
            >
              <AutocompleteMultiselectInputShrink
                disabled={isRestFiltersDisabled}
                helperText={false}
                label="Статус"
                name="statusNew"
                optionText="description"
                optionValue="value"
              />
            </ReferenceArrayInput>
            <EnumAutocompleteInputShrink
              disabled={isRestFiltersDisabled}
              filterToQuery={(searchText: string) => ({ description: searchText })}
              fullWidth
              helperText={false}
              label="Направление"
              name="direction"
              optionText="description"
              optionValue="value"
              resource="directions"
              source="direction"
              variant="outlined"
            />
            <ReferenceArrayInput
              filter={{ active: true }}
              isFilter={true}
              name="currency"
              perPage={1000}
              reference="currencies"
              sort={{ field: 'id', order: 'ASC' }}
              source="currency"
            >
              <AutocompleteMultiselectInputShrink
                disabled={isRestFiltersDisabled}
                filterToQuery={(searchText: string) => ({ currency: searchText })}
                helperText={false}
                label="Валюта"
                name="currency"
                optionText="name"
                renderChipLabel={(record: RaRecord) => `${record?.alphaCode}, ${record?.name}`}
              />
            </ReferenceArrayInput>
            <Controller
              control={form.control}
              name="amount.gte"
              render={({ field, fieldState }) => (
                <TextFieldShrink
                  InputProps={{
                    endAdornment: field.value && <OrdersFilterCloseIcon onClick={clearAmountGte} />,
                  }}
                  disabled={isRestFiltersDisabled}
                  error={!!fieldState.error}
                  fullWidth
                  helperText={fieldState.error?.message}
                  label="Сумма от"
                  onChange={(value) => field.onChange(value)}
                  value={field.value}
                  variant="outlined"
                />
              )}
              rules={{
                maxLength: { value: 10, message: 'Допустимы значения до 10 символов' },
                pattern: {
                  value: DIGIT_REGEXP,
                  message: 'Введите число',
                },
              }}
            />
            <Controller
              control={form.control}
              name="amount.lte"
              render={({ field, fieldState }) => (
                <TextFieldShrink
                  InputProps={{
                    endAdornment: field.value && <OrdersFilterCloseIcon onClick={clearAmountLte} />,
                  }}
                  disabled={isRestFiltersDisabled}
                  error={!!fieldState.error}
                  fullWidth
                  helperText={fieldState.error?.message}
                  label="до"
                  onChange={(value) => field.onChange(value)}
                  value={field.value}
                  variant="outlined"
                />
              )}
              rules={{
                maxLength: { value: 10, message: 'Допустимы значения до 10 символов' },
                pattern: {
                  value: DIGIT_REGEXP,
                  message: 'Введите число',
                },
              }}
            />
            <ReferenceArrayInput
              isFilter={true}
              name="method"
              perPage={100}
              reference="methods"
              source="method"
            >
              <AutocompleteMultiselectInputShrink
                disabled={isRestFiltersDisabled}
                helperText={false}
                label="Метод"
                name="method"
                optionText="name"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              isFilter={true}
              name="gateway"
              perPage={100}
              reference="gateways"
              source="gateway"
            >
              <AutocompleteMultiselectInputShrink
                disabled={isRestFiltersDisabled}
                helperText={false}
                label="Шлюз"
                name="gateway"
                optionText="name"
              />
            </ReferenceArrayInput>
            <Controller
              control={form.control}
              name="createdAt.gte"
              render={({ field }) => (
                <DatePicker
                  customInput={
                    <TextFieldShrink
                      InputProps={{
                        endAdornment: <OrdersFilterCalendarTodayIcon />,
                      }}
                      fullWidth
                      label="Период от"
                      size="small"
                      value={field.value}
                      variant="outlined"
                    />
                  }
                  dateFormat="dd/MM/yyyy HH:mm"
                  disabled={isRestFiltersDisabled}
                  endDate={form.watch('createdAt.lte')}
                  locale="ru"
                  maxDate={form.watch('createdAt.lte') || new Date()}
                  onChange={(date) => {
                    field.onChange(date);
                  }}
                  selected={field.value}
                  selectsStart
                  showTimeInput
                  startDate={field.value}
                  timeInputLabel="Время"
                />
              )}
            />
            <Controller
              control={form.control}
              name="createdAt.lte"
              render={({ field }) => (
                <DatePicker
                  customInput={
                    <TextFieldShrink
                      InputProps={{
                        endAdornment: <OrdersFilterCalendarTodayIcon />,
                      }}
                      fullWidth
                      label="Период до"
                      size="small"
                      value={field.value}
                      variant="outlined"
                    />
                  }
                  dateFormat="dd/MM/yyyy HH:mm"
                  disabled={isRestFiltersDisabled}
                  endDate={field.value}
                  locale="ru"
                  maxDate={new Date()}
                  minDate={form.watch('createdAt.gte')}
                  onChange={(date) => {
                    field.onChange(date);
                  }}
                  selected={field.value}
                  selectsEnd
                  showTimeInput
                  startDate={form.watch('createdAt.gte')}
                  timeInputLabel="Время"
                />
              )}
            />
          </FilterFieldGroup>
        </FilterCollapsible>
      </form>
    </FormProvider>
  );
};
