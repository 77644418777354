import { usePermissions } from 'hooks/usePermissions';
import { useNotify, useRefresh } from 'react-admin';
import { Stack } from 'shared/mui/Stack/Stack';
import { Tooltip } from 'shared/mui/Tooltip';
import { Typography } from 'shared/mui/Typography';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { GatewayRuleActiveSwitch } from 'shared/widgets/GatewayRuleActiveSwitch/GatewayRuleActiveSwitch';
import { GatewayRuleRecord, Permission } from 'types';

type Props = Omit<FunctionFieldProps, 'render'>;

export const MerchantShowRulesNameField: React.FC<Props> = ({ label, source }) => {
  const { hasResourcePermissions } = usePermissions();
  const refresh = useRefresh();
  const notify = useNotify();

  const onSuccess = () => {
    notify('Активность успешна изменена.', { type: 'success' });
    refresh();
  };

  return (
    <FunctionField
      label={label}
      render={(record: GatewayRuleRecord) => {
        return (
          <Stack alignItems="center" direction="row">
            {hasResourcePermissions('gateway_rules', [Permission.Full, Permission.Update]) && (
              <GatewayRuleActiveSwitch onSuccess={onSuccess} size="small" />
            )}
            <Tooltip title={<Typography fontSize={12}>{record.name}</Typography>}>
              <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {record.name}
              </span>
            </Tooltip>
          </Stack>
        );
      }}
      sortable={false}
      source={source}
    />
  );
};
