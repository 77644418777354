import { AxiosResponse } from 'axios';
import { required } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import {
  KeyInputIntrospected,
  TextInputIntrospected,
  TextInputWithAliasIntrospected,
} from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInput } from 'shared/react-admin/Inputs/AutocompleteMultiselectInput/AutocompleteMultiselectInput';
import { ReferenceArrayInputIntrospected } from 'shared/react-admin/ReferenceInputs';

import { useGenerateNotificationKey } from '../hooks/useGenerateNotificationKey';
import { useNotificationKeyValidation } from '../hooks/useNotificationKeyValidation';

export const MerchantsCreateForm = () => {
  const { setValue, clearErrors } = useFormContext();
  const { generateNotificationKey } = useGenerateNotificationKey({
    onSuccess: (data: AxiosResponse) => {
      clearErrors('notificationKey');
      setValue('notificationKey', data.data.value);
    },
  });
  const { notificationKeyValidation } = useNotificationKeyValidation();
  return (
    <>
      <TextInputIntrospected fullWidth label="Название" source="name" variant="outlined" />
      <TextInputWithAliasIntrospected fullWidth label="Алиас" source="alias" variant="outlined" />
      <KeyInputIntrospected
        fullWidth
        label="Ключ нотификации"
        onClickGenerateKey={generateNotificationKey}
        source="notificationKey"
        validate={[required(), notificationKeyValidation]}
        variant="outlined"
      />
      <ReferenceArrayInputIntrospected
        filter={{ archive: false }}
        perPage={200}
        reference="companies"
        source="companies"
      >
        <AutocompleteMultiselectInput
          helperText={false}
          label="Компания"
          name="companies"
          optionText="name"
          size="medium"
        />
      </ReferenceArrayInputIntrospected>
    </>
  );
};
