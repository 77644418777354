import React from 'react';

import { Autocomplete, Chip, TextField } from '@mui/material';
import { TextInput, TextInputProps, useInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { useOrdersFilterContext } from '../../context/ordersFilterContext';

export function OrdersFilterSearchByIdDialogInput(props: TextInputProps): JSX.Element {
  const { field } = useInput(props);
  const { notFoundOrderIds } = useOrdersFilterContext();
  const { setValue } = useFormContext();

  if (Array.isArray(field.value) && field.value?.length) {
    return (
      <Autocomplete
        disableClearable={true}
        disabled={true}
        freeSolo
        multiple
        options={[]}
        renderInput={(params) => (
          <TextField
            {...params}
            {...props}
            InputProps={{ ...params.InputProps, ...props.InputProps }}
            sx={{
              '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                padding: '8px 16px',
                alignItems: 'start',
              },
            }}
          />
        )}
        renderTags={(value: string[], getTagProps) =>
          value.map((id, index: number) => (
            <Chip
              color={notFoundOrderIds?.includes(id) ? 'warning' : 'default'}
              label={id}
              {...getTagProps({ index })}
              key={index}
              onDelete={() =>
                setValue(
                  field.name,
                  field.value.filter((value: string) => value !== id),
                )
              }
              size="small"
              sx={{
                '&.Mui-disabled': {
                  opacity: 1,
                  pointerEvents: 'all',
                },
              }}
            />
          ))
        }
        value={field.value}
      />
    );
  }
  return <TextInput {...props}></TextInput>;
}
