import { Dispatch, SetStateAction } from 'react';

import { required } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Checkbox } from 'shared/mui/Checkbox/Checkbox';
import { FormControlLabel } from 'shared/mui/FormControlLabel/FormControlLabel';
import { TooltipWithInfoIcon } from 'shared/mui/Tooltip';
import { AutocompleteInputShrink, BooleanInput, TextInput } from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

type UsersFormProps = {
  hasCorporateEmail?: boolean;
  setHasCorporateEmail: Dispatch<SetStateAction<boolean>>;
};

export const UsersForm: React.FC<UsersFormProps> = ({
  hasCorporateEmail,
  setHasCorporateEmail,
}) => (
  <CardContent sx={{ paddingTop: '0!important' }}>
    <BooleanInput
      label="Активен"
      name="enabled"
      source="enabled"
      sx={{ marginBottom: '24px' }}
      variant="outlined"
    />
    <TextInput
      InputLabelProps={{ shrink: true }}
      fullWidth
      label="Имя"
      size="medium"
      source="firstName"
      validate={required()}
      variant="outlined"
    />
    <TextInput
      InputLabelProps={{ shrink: true }}
      fullWidth
      label="Фамилия"
      size="medium"
      source="lastName"
      validate={required()}
      variant="outlined"
    />
    <TextInput
      InputLabelProps={{ shrink: true }}
      fullWidth
      label="Электронная почта"
      size="medium"
      source="email"
      validate={required()}
      variant="outlined"
    />
    <ReferenceInput perPage={200} reference="roles" source="roleEntities">
      <AutocompleteInputShrink
        filterToQuery={(searchText: string) => ({ name: searchText })}
        format={(v) => v?.[0] || null}
        fullWidth
        label="Роль"
        optionText="name"
        parse={(v) => (v ? [v] : [])}
        size="medium"
        source="roleEntities"
        sx={{ marginBottom: '24px' }}
        validate={required()}
        variant="outlined"
      />
    </ReferenceInput>
    <TextInput
      InputLabelProps={{ shrink: true }}
      fullWidth
      label="Reddy"
      size="medium"
      source="reddyNumber"
      validate={required()}
      variant="outlined"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={hasCorporateEmail}
          onChange={() => setHasCorporateEmail(!hasCorporateEmail)}
        />
      }
      label={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          Групповой email
          <TooltipWithInfoIcon
            componentsProps={{ popper: { sx: { width: '247px' } } }}
            iconSize={24}
            tooltipText="Используется для отправки отчетов разных пользователей на один email."
          />
        </Box>
      }
      sx={{ marginBottom: '20px' }}
    />
    {hasCorporateEmail && (
      <TextInput
        InputLabelProps={{ shrink: true }}
        fullWidth
        label="Email"
        name="corporateEmail"
        size="medium"
        source="corporateEmail"
        variant="outlined"
      />
    )}
    <BooleanInput label="2FA" name="twoFactor" source="twoFactor" variant="outlined" />
  </CardContent>
);
