import { useMemo } from 'react';

import { useFormContext } from 'react-hook-form';

import { getLimitTagsText } from '../../../../../../utils/getLimitTagsText';
import { Chip } from '../../../../Chips';
import { useFilterContext } from '../../../context/filterContext';

export type FilterIdChipProps = {
  label: string;
  name: string;
  limitTags?: number;
  onClick?: () => void;
};

export const FilterIdChip: React.FC<FilterIdChipProps> = ({ name, label, onClick, limitTags }) => {
  const { setAppliedFilter, appliedFilters } = useFilterContext();
  const { resetField } = useFormContext();

  const tags = useMemo(() => {
    return getLimitTagsText(appliedFilters[name] || [], limitTags);
  }, [appliedFilters[name]]);

  if (!appliedFilters[name] || !appliedFilters[name].length) {
    return <></>;
  }

  return (
    <>
      <Chip
        label={`${label}: ${tags}`}
        onClick={onClick}
        onDelete={() => {
          resetField(name);
          setAppliedFilter(name, []);
        }}
        size="medium"
      />
    </>
  );
};
