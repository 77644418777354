import { useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useRecordModal } from 'hooks/useRecordModal';
import { Identifier } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { Box } from 'shared/mui/Box';
import { Checkbox } from 'shared/mui/Checkbox/Checkbox';
import { FormControlLabel } from 'shared/mui/FormControlLabel/FormControlLabel';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterClearButton,
  FilterResetButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { PageHeader } from 'shared/mui/PageHeader';
import { Typography } from 'shared/mui/Typography';
import { GatewayConversionListItem } from 'types/orderConversion';
import { getId } from 'utils';
import { cleanEmpty } from 'utils/cleanEmpty';
import * as yup from 'yup';

import { useOrderConversion } from './hooks/useOrderConversion';
import { OrderConversionListConversion } from './OrderConversionListConversion/OrderConversionListConversion';
import { OrderConversionListFilter } from './OrderConversionListFilter/OrderConversionListFilter';
import { OrderConversionListFilterChips } from './OrderConversionListFilterChips/OrderConversionListFilterChips';
import { OrderConversionListGateway } from './OrderConversionListGateway/OrderConversionListGateway';
import { OrderConversionListGatewayCreate } from './OrderConversionListGateway/OrderConversionListGatewayCreate/OrderConversionListGatewayCreate';
import { OrderConversionListGatewayDelete } from './OrderConversionListGateway/OrderConversionListGatewayDelete/OrderConversionListGatewayDelete';
import { OrderConversionListGatewayEdit } from './OrderConversionListGateway/OrderConversionListGatewayEdit/OrderConversionListGatewayEdit';
import { OrderConversionListOrders } from './OrderConversionListOrders/OrderConversionListOrders';
import {
  ConversionPeriod,
  OrderConversionListPeriodToggle,
} from './OrderConversionListPeriodToggle/OrderConversionListPeriodToggle';
import { OrderConversionListRefreshDisable } from './OrderConversionListRefreshDisable/OrderConversionListRefreshDisable';
import { OrderConversionListRefreshEnable } from './OrderConversionListRefreshEnable/OrderConversionListRefreshEnable';
import { getPeriod } from './utils/getPeriod';
import { OrderConversionRequestParams } from '../../../../config/requests';

export type OrderConversionListFormValues = {
  merchant: string | null;
  gateway: string[];
  provider: string[];
};

export const OrderConversionList: React.FC = () => {
  const [period, setPeriod] = useState<ConversionPeriod>(ConversionPeriod.Minutes60);

  const filterPeriod = useMemo(() => {
    switch (period) {
      case ConversionPeriod.Minutes60:
        return getPeriod({ minutes: 60 });
      case ConversionPeriod.Hours3:
        return getPeriod({ hours: 3 });
      case ConversionPeriod.Hours5:
        return getPeriod({ hours: 5 });
      case ConversionPeriod.Hours7:
        return getPeriod({ hours: 7 });
      default:
        return getPeriod({ minutes: 60 });
    }
  }, [period]);

  const { form, onSubmit, onReset, appliedFilters, setAppliedFilter, openDrawer, toggleDrawer } =
    useFilters<OrderConversionListFormValues>({
      resolver: yupResolver<any>(
        yup.object().shape({
          merchant: yup.string().required('Обязательное поле'),
        }),
      ),
      defaultValues: {
        merchant: null,
        gateway: [],
        provider: [],
      },
    });

  const params = useMemo<OrderConversionRequestParams>(() => {
    return cleanEmpty({
      gatewayIds: appliedFilters?.gateway?.map((hydraId) => getId(hydraId)),
      providerIds: appliedFilters?.provider?.map((hydraId) => getId(hydraId)),
      period: filterPeriod,
      merchantId: getId(appliedFilters?.merchant as Identifier),
    }) as OrderConversionRequestParams;
  }, [filterPeriod, appliedFilters]);

  const [autoRefresh, setAutoRefresh] = useState(false);

  const [openRefreshEnable, setOpenRefreshEnable] = useState(false);
  const [openRefreshDisable, setOpenRefreshDisable] = useState(false);

  const onChangeAutoRefresh = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    checked ? setOpenRefreshEnable(true) : setOpenRefreshDisable(true);
  };

  const { data, isLoading, isFetching } = useOrderConversion(params, autoRefresh ? 60000 : false);
  const [isOnlyReject, setIsOnlyReject] = useState<boolean>(false);

  const {
    record: recordCreate,
    handleOpenRecordModal: handleOpenCreateModal,
    openRecordModal: openCreateModal,
    handleCloseRecordModal: handleCloseCreateModal,
  } = useRecordModal<GatewayConversionListItem>();

  const {
    record: recordEdit,
    handleOpenRecordModal: handleOpenEditModal,
    openRecordModal: openEditModal,
    handleCloseRecordModal: handleCloseEditModal,
  } = useRecordModal<GatewayConversionListItem>();

  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenDeleteModal,
    openRecordModal: openDeleteModal,
    handleCloseRecordModal: handleCloseDeleteModal,
  } = useRecordModal<GatewayConversionListItem>();

  const gatewayConversionList = useMemo(() => {
    if (!isOnlyReject) {
      return data?.gatewayConversionList;
    }

    return data?.gatewayConversionList.filter(
      (gatewayConversion) => gatewayConversion.messages.length > 0,
    );
  }, [isOnlyReject, data?.gatewayConversionList]);

  const isListFilterExist = Object.keys(cleanEmpty(appliedFilters)).length > 0;

  return (
    <div>
      <PageHeader
        actions={[]}
        headerTitle={{
          titleText: 'Мониторинг конверсии заказов',
          tooltipText:
            'Конверсия заказов — это метрика, отражающая процентное соотношение заказов, находящихся в состоянии «выполнен», «ожидание» или «отклонен» за выбранный период времени.',
        }}
        sx={{ marginTop: '24px' }}
      />
      <FilterToolbar
        leftActionsSlot={[
          <FilterButton key="filter" onClick={toggleDrawer(true)} />,
          <FilterClearButton
            key="clear"
            onClick={() => {
              onReset();
            }}
            visible={isListFilterExist}
          />,
        ]}
        rightActionsSlot={[
          <OrderConversionListPeriodToggle
            key="toggle"
            onChange={(_, newValue) => setPeriod(newValue)}
            value={period}
          />,
        ]}
      />
      <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FilterDrawerContent sx={{ gap: '0' }}>
              <OrderConversionListFilter />
            </FilterDrawerContent>
            <FilterDrawerActionsButtons>
              <FilterApplyButton type="submit" />
              <FilterResetButton label="Очистить" onReset={onReset} />
            </FilterDrawerActionsButtons>
          </form>
        </FormProvider>
      </FilterDrawer>
      <FilterChipsToolbar appliedFilters={appliedFilters}>
        <FilterContextProvider appliedFilters={appliedFilters} setAppliedFilter={setAppliedFilter}>
          <FormProvider {...form}>
            <OrderConversionListFilterChips />
          </FormProvider>
        </FilterContextProvider>
      </FilterChipsToolbar>
      {!isLoading && (
        <>
          {!data ? (
            <Alert severity="info">Пожалуйста, укажите мерчанта для отображения статистики</Alert>
          ) : (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <OrderConversionListConversion
                  autoRefresh={autoRefresh}
                  items={data?.summaryConversionItems || []}
                  onChangeAutoRefresh={onChangeAutoRefresh}
                />
                <OrderConversionListRefreshEnable
                  onClose={() => setOpenRefreshEnable(false)}
                  onConfirm={() => {
                    setAutoRefresh(true);
                  }}
                  open={openRefreshEnable}
                />
                <OrderConversionListRefreshDisable
                  onClose={() => setOpenRefreshDisable(false)}
                  onConfirm={() => {
                    setAutoRefresh(false);
                  }}
                  open={openRefreshDisable}
                />
                <OrderConversionListOrders orders={data?.totalOrders} />
              </Box>
              <PageHeader
                actions={[
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isOnlyReject}
                        onChange={(_, checked) => {
                          setIsOnlyReject(checked);
                        }}
                      />
                    }
                    key="conversion"
                    label={<Typography component="span">Показывать только отклонения</Typography>}
                    sx={{ marginRight: 0 }}
                  />,
                ]}
                headerTitle={{ titleText: 'Список шлюзов' }}
                sx={{ marginTop: '21px' }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {gatewayConversionList?.map((listItem) => {
                  return (
                    <OrderConversionListGateway
                      isLoading={isFetching}
                      key={listItem.id}
                      listItem={listItem}
                      onOpenCreateModal={handleOpenCreateModal}
                      onOpenEditModal={handleOpenEditModal}
                    />
                  );
                })}
              </Box>
            </>
          )}
        </>
      )}
      <OrderConversionListGatewayCreate
        merchant={form.getValues('merchant')}
        onClose={handleCloseCreateModal}
        open={openCreateModal}
        params={params}
        record={recordCreate}
      />
      <OrderConversionListGatewayEdit
        onClose={handleCloseEditModal}
        onDelete={(record) => {
          handleOpenDeleteModal(record);
          handleCloseEditModal();
        }}
        open={openEditModal}
        params={params}
        record={recordEdit}
      />
      <OrderConversionListGatewayDelete
        onCancel={(record) => {
          handleOpenEditModal(record);
          handleCloseDeleteModal();
        }}
        onClose={handleCloseDeleteModal}
        open={openDeleteModal}
        record={recordDelete}
      />
    </div>
  );
};
