import { AppReferenceOneField } from 'components/Common';
import { BooleanField, TextField } from 'react-admin';
import { CreateButtonToEntity } from 'shared/mui/Buttons';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { SensitiveDataField } from 'shared/react-admin/Fields';

import { ApiKeysFilters } from './ApiKeysFilters';
import { usePermissions } from '../../hooks/usePermissions';
import { Permission } from '../../types';
import { AppList } from '../Common/List';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../Common/PanelHeader';

const ApiKeysList: React.FC = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  return (
    <AppList>
      <AppPanelHeader>
        <AppPanelHeaderToolbar
          actions={[
            hasResourcePermissions('api_keys', [Permission.Full, Permission.Create]) && (
              <CreateButtonToEntity entity="apiClient" />
            ),
          ]}
          titleText="Ключи API"
          tooltipText="Ключ API используется клиентом API для доступа к API Kubera"
        />
      </AppPanelHeader>
      <ApiKeysFilters />
      <Datagrid>
        <TextField label="Id" sortBy="id" source="originId" />
        {hasResourceAccess('api_clients') && (
          <AppReferenceOneField
            label="Api клиент"
            reference="api_clients"
            sortBy="apiClient.name"
            source="apiClient"
            target="id"
          >
            <TextField source="name" />
          </AppReferenceOneField>
        )}
        <SensitiveDataField label="Значение" source="value" />
        <BooleanField label="Включен" source="active" />
      </Datagrid>
    </AppList>
  );
};

export default ApiKeysList;
