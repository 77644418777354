import { DEFAULT_PAGINATION } from 'constants/defaultPagination';

import { useEffect, useState } from 'react';

import ContentAdd from '@mui/icons-material/Add';
import { MerchantNotificationsFilters } from 'config/requests';
import { useRecordModal } from 'hooks/useRecordModal';
import { ListContextProvider, useList } from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import { Alert } from 'shared/mui/Alert/Alert';
import { Button } from 'shared/mui/Buttons';
import { DeleteIconButton, EditIconButton, ShowIconButton } from 'shared/mui/IconButtons';
import { PageHeader } from 'shared/mui/PageHeader';
import { DateTimeField, TextField } from 'shared/react-admin/Fields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { Merchant, MerchantNotification, Permission } from 'types';

import { useGetMerchantNotifications } from './hooks/useGetMerchantNotifications';
import { useGetMerchantNotificationsEventNames } from './hooks/useGetMerchantNotificationsEventNames';
import { StyledDatagrid, StyledPagination } from './MerchantsShowNotifications.styled';
import { MerchantsShowNotificationsCreateDialog } from './MerchantsShowNotificationsCreateDialog/MerchantsShowNotificationsCreateDialog';
import { MerchantsShowNotificationsDeleteDialog } from './MerchantsShowNotificationsDeleteDialog/MerchantsShowNotificationsDeleteDialog';
import { MerchantsShowNotificationsDeliveryChannelField } from './MerchantsShowNotificationsDeliveryChannelField/MerchantsShowNotificationsDeliveryChannelField';
import { MerchantsShowNotificationsEditDialog } from './MerchantsShowNotificationsEditDialog/MerchantsShowNotificationsEditDialog';
import { MerchantsShowNotificationsSendNotificationDialog } from './MerchantsShowNotificationsSendNotificationDialog/MerchantsShowNotificationsSendNotificationDialog';
import { MerchantsShowNotificationsShowDialog } from './MerchantsShowNotificationsShowDialog/MerchantsShowNotificationsShowDialog';
import { usePermissions } from '../../../../hooks/usePermissions';

type MerchantsShowNotificationsProps = {
  record?: Merchant;
};

export type MerchantsShowNotificationsDialogsType = 'show' | 'edit' | null;

export const MerchantsShowNotifications: React.FC<MerchantsShowNotificationsProps> = ({
  record,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [filters, setFilters] = useState<MerchantNotificationsFilters>(DEFAULT_PAGINATION);
  const [previousDialog, setPreviousDialog] = useState<MerchantsShowNotificationsDialogsType>(null);
  const { eventNames } = useGetMerchantNotificationsEventNames();

  const { merchantNotifications, isLoading, totalItems, isFetching } = useGetMerchantNotifications({
    filters,
    merchantId: record?.id,
  });

  const listContext = useList({
    data: merchantNotifications || [],
    isFetching,
    isLoading,
    page: filters.page,
    perPage: filters.itemsPerPage,
  });
  const { hasResourcePermissions } = usePermissions();

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      page: Number(searchParams.get('page')) || DEFAULT_PAGINATION.page,
      itemsPerPage: Number(searchParams.get('itemsPerPage')) || DEFAULT_PAGINATION.itemsPerPage,
    }));
  }, [searchParams]);

  const {
    record: recordEditDialog,
    handleCloseRecordModal: handleCloseEditDialog,
    openRecordModal: openEditDialog,
    handleOpenRecordModal: handleOpenEditDialog,
  } = useRecordModal<MerchantNotification>();
  const {
    record: recordShowDialog,
    handleCloseRecordModal: handleCloseShowDialog,
    openRecordModal: openShowDialog,
    handleOpenRecordModal: handleOpenShowDialog,
  } = useRecordModal<MerchantNotification>();
  const {
    record: recordDeleteDialog,
    handleCloseRecordModal: handleCloseDeleteDialog,
    openRecordModal: openDeleteDialog,
    handleOpenRecordModal: handleOpenDeleteDialog,
  } = useRecordModal<MerchantNotification>();
  const {
    record: recordSendNotificationDialog,
    handleCloseRecordModal: handleCloseSendNotificationDialog,
    openRecordModal: openSendNotificationDialog,
    handleOpenRecordModal: handleOpenSendNotificationDialog,
  } = useRecordModal<MerchantNotification>();

  const handleCloseNotificationDialog = () => {
    handleCloseSendNotificationDialog();
    handleOpenShowDialog(recordSendNotificationDialog);
  };

  const deleteDialogClose = () => {
    if (previousDialog === 'show') {
      handleOpenShowDialog(recordDeleteDialog);
    }
    if (previousDialog === 'edit') {
      handleOpenEditDialog(recordDeleteDialog);
    }
    handleCloseDeleteDialog();
  };

  return (
    <ListContextProvider value={listContext}>
      <PageHeader
        actions={[
          hasResourcePermissions('merchant_notification_settings', [
            Permission.Full,
            Permission.Create,
          ]) && (
            <Button
              key="create"
              label="Добавить"
              onClick={() => setOpenCreateDialog(true)}
              startIcon={<ContentAdd />}
              sx={{ marginTop: '8px', padding: '3px 16px' }}
              variant="contained"
            />
          ),
        ]}
        headerTitle={{
          titleText: 'Настройки уведомлений',
          titleSize: 'small',
        }}
      />
      {!totalItems && !isLoading ? (
        <Alert severity="info">{`Нет настроек для отправки уведомлений для мерчанта ${record?.name}`}</Alert>
      ) : (
        <>
          <StyledDatagrid>
            <TextField label="ID" sortBy="id" sortable={false} source="id" />
            <MerchantsShowNotificationsDeliveryChannelField label="Канал доставки" />
            <DateTimeField label="Дата создания" sortable={false} source="createdAt" />
            <DateTimeField label="Дата редактирования" sortable={false} source="updatedAt" />
            <ActionsSlots
              render={(record: MerchantNotification) => (
                <>
                  {hasResourcePermissions('merchant_notification_settings', [
                    Permission.Full,
                    Permission.Read,
                  ]) && <ShowIconButton onClick={() => handleOpenShowDialog(record)} />}
                  {hasResourcePermissions('merchant_notification_settings', [
                    Permission.Full,
                    Permission.Update,
                  ]) && <EditIconButton onClick={() => handleOpenEditDialog(record)} />}
                  {hasResourcePermissions('merchant_notification_settings', [
                    Permission.Full,
                    Permission.Delete,
                  ]) && <DeleteIconButton onClick={() => handleOpenDeleteDialog(record)} />}
                </>
              )}
              sx={{ marginTop: '-6px' }}
            />
          </StyledDatagrid>
          <StyledPagination
            page={filters.page}
            perPage={filters.itemsPerPage}
            setPage={(page) => {
              setSearchParams((prev) => {
                return { ...Object.fromEntries(prev), page: page.toString() };
              });
              setFilters((prev) => ({ ...prev, page: page }));
            }}
            setPerPage={(itemsPerPage) => {
              setFilters((prev) => ({ ...prev, itemsPerPage: itemsPerPage }));
              setSearchParams((prev) => {
                return { ...Object.fromEntries(prev), itemsPerPage: itemsPerPage.toString() };
              });
            }}
            total={totalItems}
          />
        </>
      )}
      {openCreateDialog && (
        <MerchantsShowNotificationsCreateDialog
          eventNames={eventNames}
          merchantId={record?.id as string}
          onClose={() => setOpenCreateDialog(false)}
          open={openCreateDialog}
        />
      )}
      {openShowDialog && recordShowDialog && (
        <MerchantsShowNotificationsShowDialog
          onClose={() => {
            setPreviousDialog(null);
            handleCloseShowDialog();
          }}
          onDelete={() => {
            handleOpenDeleteDialog(recordShowDialog);
            setPreviousDialog('show');
          }}
          onEdit={handleOpenEditDialog}
          onSendNotification={() => handleOpenSendNotificationDialog(recordShowDialog)}
          open={openShowDialog}
          record={recordShowDialog}
        />
      )}
      {openEditDialog && recordEditDialog && (
        <MerchantsShowNotificationsEditDialog
          eventNames={eventNames}
          merchantId={record?.id as string}
          onClose={() => {
            setPreviousDialog(null);
            handleCloseEditDialog();
          }}
          onDelete={() => {
            handleOpenDeleteDialog(recordEditDialog);
            setPreviousDialog('edit');
          }}
          open={openEditDialog}
          record={recordEditDialog}
        />
      )}
      {openDeleteDialog && recordDeleteDialog && (
        <MerchantsShowNotificationsDeleteDialog
          onClose={deleteDialogClose}
          onCloseSuccess={handleCloseDeleteDialog}
          open={openDeleteDialog}
          record={recordDeleteDialog}
        />
      )}
      {openSendNotificationDialog && recordSendNotificationDialog && (
        <MerchantsShowNotificationsSendNotificationDialog
          onClose={handleCloseNotificationDialog}
          open={openSendNotificationDialog}
        />
      )}
    </ListContextProvider>
  );
};
