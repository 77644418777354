import { useMemo } from 'react';

import { useGetMany } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { getLimitTagsText } from '../../../../../../utils/getLimitTagsText';
import { Chip } from '../../../../Chips';
import { useAnchor } from '../../../../DatePicker/hooks/useAnchor';
import { useFilterContext } from '../../../context/filterContext';
import { PopoverChip } from '../components/PopoverChip/PopoverChip';

export type FilterMultiselectChipProps = {
  label: string;
  resource: string;
  name: string;
  limitTags?: number;
  children: React.ReactNode;
  onDelete?: () => void;
  onApply?: () => void;
  onReset?: () => void;
};

export const FilterMultiselectChip: React.FC<FilterMultiselectChipProps> = ({
  resource,
  name,
  label,
  limitTags,
  onApply,
  children,
  onDelete,
  onReset,
}) => {
  const { resetField, getValues, setValue } = useFormContext();
  const { setAppliedFilter, appliedFilters } = useFilterContext();
  const { handleClose, handleClick, anchorEl } = useAnchor<HTMLDivElement>();

  const { data = [] } = useGetMany(
    resource,
    { ids: appliedFilters[name] },
    { enabled: Boolean(appliedFilters[name]), keepPreviousData: true },
  );

  const tags = useMemo(() => {
    const names = data.map((item) => item.name);

    return getLimitTagsText(names, limitTags);
  }, [data]);

  if (!appliedFilters[name] || !appliedFilters[name].length) {
    return <></>;
  }

  function onApplyHandler() {
    handleClose();
    onApply && onApply();
    setAppliedFilter(name, getValues(name));
  }

  function onResetHandler() {
    onReset && onReset();
    setAppliedFilter(name, appliedFilters?.[name]);
    setValue(name, appliedFilters?.[name]);
  }
  const open = Boolean(anchorEl);

  return (
    <>
      <PopoverChip
        anchorEl={anchorEl}
        onApply={onApplyHandler}
        onClose={handleClose}
        onReset={onResetHandler}
        open={open}
      >
        {children}
      </PopoverChip>
      <Chip
        label={`${label}: ${tags}`}
        onClick={handleClick}
        onDelete={() => {
          onDelete && onDelete();
          resetField(name);
          setAppliedFilter(name, []);
        }}
        size="medium"
      />
    </>
  );
};
