import {
  EXPORT_FORMAT_NAMES,
  ExportFormats,
  ExportJobTypes,
  ExportTransportTypes,
  ReportNames,
} from 'constants/exportFormats';

import React, { useEffect, useMemo, useState } from 'react';

import { OrdersReportFilterContextProvider } from 'components/Finance/OrdersReport/context/ordersReportContext';
import { OrdersReportFilter } from 'components/Finance/OrdersReport/OrdersReportFilter';
import { OrdersReportLocalDatagrid } from 'components/Finance/OrdersReport/OrdersReportLocalDatagrid';
import { getUserInfo } from 'config/requests';
import { useReportExport } from 'hooks/queries/useReportExport';
import { useGetExistingIdFilterName } from 'hooks/useGetExistingIdFilterName';
import { useGetNotFoundOrderIds } from 'hooks/useGetNotFoundOrderIds';
import { useListController, SortPayload, WithListContext, useNotify } from 'react-admin';
import { useQuery } from 'react-query';
import { Alert } from 'shared/mui/Alert/Alert';
import { Box } from 'shared/mui/Box';
import { NotifyDialog } from 'shared/mui/Dialogs';
import { Typography } from 'shared/mui/Typography';
import { ExportEditButton } from 'shared/react-admin/Export/ExportEditButton/ExportEditButton';
import { ListPage } from 'shared/react-admin/Pages';
import { ReportExportDialogMessage } from 'shared/widgets/ReportExport/ReportExportDialogMessage';
import { Order, ExportFormat } from 'types';

import { EXPORT_FIELDS_ORDERS_REPORT } from './constants/exportFields';
import { ExportFormatsButton } from '../../../shared/mui/Buttons/ExportFormatsButton/ExportFormatsButton';

export const OrdersReportList: React.FC = () => {
  const {
    filterValues,
    isLoading: isListLoading,
    refetch,
  } = useListController<Order>({
    resource: 'orders/report',
    sort: {} as SortPayload,
    queryOptions: { enabled: false },
  });

  const [isOpenNotifyDialog, setIsOpenNotifyDialog] = useState(false);
  const [isSendReportOnEmail, setIsSendReportOnEmail] = useState(false);
  const notify = useNotify();
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [exportFormat, setExportFormat] = useState<ExportFormat>(ExportFormats.Excel);

  const { data: user } = useQuery('me', getUserInfo);

  const isFiltersApplied = useMemo(() => Object.keys(filterValues).length > 0, [filterValues]);

  // Требуется для первоначального обновления списка полей в LocalStorage.
  // Описания полей в настройке колонок хранятся в localStorage. В задаче WPPA-6017 требовалось изменить названия полей.
  // Для того, чтобы они обновились в localStorage и отображались клиенту корректно, требуется при первой загрузке пользователем
  // данного раздела - очистить поля в LS, после этого устанавливается флаг isNotFirstOrdersReportListRender = true в LS
  // и больше данная функция не вызывается никогда.
  // ToDo Спустя какое-то время (1-3 мес.) ее можно удалить, тк за этот период все пользователи уже посетят данный раздел.
  useEffect(() => {
    const isNotFirstOrdersReportListRender = localStorage.getItem(
      'isNotFirstOrdersReportListRender',
    );
    const isNotFirstOrdersReportExportRender = localStorage.getItem(
      'isNotFirstOrdersReportExportRender',
    );
    if (!isNotFirstOrdersReportListRender) {
      localStorage.removeItem('RaStore.preferences.orders/report.datagrid.availableColumns');
    }
    if (!isNotFirstOrdersReportExportRender) {
      localStorage.removeItem('RaStore.preferences.orders_report.export.availableFields');
    }
    return () => {
      localStorage.setItem('isNotFirstOrdersReportListRender', 'true');
      localStorage.setItem('isNotFirstOrdersReportExportRender', 'true');
    };
  }, []);

  const { mutate: exportMutate, isLoading: isExportLoading } = useReportExport({
    filters: {
      ...filterValues,
      exportType: exportFormat,
      jobType: ExportJobTypes.Success_transaction,
      ...(isSendReportOnEmail && { notificationTransport: ExportTransportTypes.Email }),
    },
    preferenceKey: ReportNames.Orders_report,
    onSuccess: () => {
      notify(
        `Файл экспорта исполненных транзакций формируется ${isSendReportOnEmail ? 'и будет отправлен вам на почту' : ''}`,
        {
          type: 'info',
        },
      );
      setIsOpenNotifyDialog(false);
    },
    onError: (error) => {
      notify(error.data?.errors[0]?.title, { type: 'error' });
    },
  });

  useEffect(() => {
    if (isFiltersApplied) {
      refetch()?.then(() => setIsShowAlert(true));
    }
  }, [filterValues]);

  return (
    <ListPage
      actions={[
        <ExportFormatsButton
          exportFormat={exportFormat}
          key="export-button"
          onChangeFormat={setExportFormat}
          onClick={() => setIsOpenNotifyDialog(true)}
        />,
      ]}
      empty={false}
      headerTitle={{
        titleText: 'Отчет по исполненным транзакциям',
        tooltipText:
          'Отчет по исполненным транзакциям формирует сведения только по исполненным / успешным заказам (со статусом успех). Для получения информации по не исполненным транзакциям, перейдите в раздел “Заказы”',
      }}
      listBoxProps={{ sx: { maxHeight: '100%' } }}
      pagination={<></>}
      queryOptions={{ enabled: isFiltersApplied }}
      resource="orders/report"
      sort={{} as SortPayload}
    >
      <WithListContext
        render={({ isLoading, data: orders, total, isFetching }) => {
          const notFoundOrdersReportIds = useGetNotFoundOrderIds(orders as Order[], filterValues);
          const existingIdFilterName = useGetExistingIdFilterName(filterValues);

          function renderLimitAlert() {
            if (!isShowAlert) {
              return <></>;
            }

            return (
              <Alert
                onClose={() => {
                  setIsShowAlert(false);
                }}
                severity="warning"
                sx={{ margin: '15px 0 10px 0' }}
              >
                Применённый фильтр слишком широкий, поэтому показано только 1000 записей. Чтобы
                получить все результаты фильтрации, сделайте экспорт.
              </Alert>
            );
          }

          function renderNotFoundOrdersAlert() {
            if (notFoundOrdersReportIds.length && !isLoading) {
              const ids = notFoundOrdersReportIds.join(' ');

              return (
                <Alert severity="warning" sx={{ margin: '15px 0 10px 0' }}>
                  {`Заказы с ID: ${ids} в результате поиска по ${existingIdFilterName} не найдены в системе как исполненные.`}
                </Alert>
              );
            }

            return undefined;
          }

          function renderAlert() {
            if ((total || 0) >= 1001 && isFiltersApplied) {
              return renderLimitAlert();
            }

            return renderNotFoundOrdersAlert();
          }

          return (
            <OrdersReportFilterContextProvider value={{ notFoundOrdersReportIds }}>
              <OrdersReportFilter alert={renderAlert()} />
              <Box
                sx={{
                  minHeight: '100%',
                  display: 'initial',
                }}
              >
                <OrdersReportLocalDatagrid
                  isLoading={isLoading}
                  orders={isFiltersApplied && !isFetching ? (orders as Order[]) || [] : []}
                  total={isFiltersApplied && !isFetching ? total || 0 : 0}
                />
              </Box>
            </OrdersReportFilterContextProvider>
          );
        }}
      />
      <NotifyDialog
        isLoading={isListLoading || isExportLoading}
        message={
          <ReportExportDialogMessage
            exportFormat={EXPORT_FORMAT_NAMES[exportFormat]}
            isSendReportOnEmail={isSendReportOnEmail}
            onChange={() => setIsSendReportOnEmail((prev) => !prev)}
            userEmail={user?.corporateEmail || user?.email || 'почта неизвестна'}
          />
        }
        onClose={() => setIsOpenNotifyDialog(false)}
        onSubmit={exportMutate}
        open={isOpenNotifyDialog}
        title={
          <>
            <Typography component="span" sx={{ verticalAlign: 'middle' }} variant="inherit">
              Экспорт отчета
            </Typography>
            <ExportEditButton
              fields={EXPORT_FIELDS_ORDERS_REPORT}
              preferenceKey={ReportNames.Orders_report}
            />
          </>
        }
      />
    </ListPage>
  );
};
