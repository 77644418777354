import { Alert } from 'shared/mui/Alert/Alert';

type OrderListNotFoundIdAlertProps = {
  isFetching: boolean;
  ids: string[];
};

export const OrderListNotFoundIdAlert: React.FC<OrderListNotFoundIdAlertProps> = ({
  isFetching,
  ids,
}) => {
  if (isFetching || ids?.length <= 0) {
    return <></>;
  }

  return (
    <Alert severity="warning" sx={{ margin: '15px 0 10px 0' }}>
      {ids?.length} ID заказов не найдены в системе.
    </Alert>
  );
};
