import { NumberField, TextField } from 'react-admin';
import { CreateButtonToEntity } from 'shared/mui/Buttons';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { ApiClientWhiteIpAddressesFilters } from './ApiClientWhiteIpAddressesFilters';
import { usePermissions } from '../../hooks/usePermissions';
import { Permission } from '../../types';
import { AppReferenceOneField } from '../Common';
import { AppListEditButton } from '../Common/Buttons';
import { AppList } from '../Common/List';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../Common/PanelHeader';

const ApiClientWhiteIpAddressesList = (): JSX.Element => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  return (
    <AppList>
      <AppPanelHeader>
        <AppPanelHeaderToolbar
          actions={[
            hasResourcePermissions('api_client_white_ip_addresses', [
              Permission.Full,
              Permission.Create,
            ]) && (
              <CreateButtonToEntity entity="apiClient" key="create_api_client_white_ip_addresses" />
            ),
          ]}
          titleText="Клиенты API – белые IP-адреса"
          tooltipText="IP-адреса, с которых клиенту API разрешено отправлять запросы к API Kubera."
        />
      </AppPanelHeader>
      <ApiClientWhiteIpAddressesFilters />
      <Datagrid>
        <TextField label="Id" sortBy="id" source="originId" />
        {hasResourceAccess('api_clients') && (
          <AppReferenceOneField
            label="Api клиент"
            reference="api_clients"
            sortBy="apiClient.name"
            source="apiClient"
            target="id"
          >
            <TextField source="name" />
          </AppReferenceOneField>
        )}
        <TextField source="ip" />
        <NumberField label="Маска" source="mask" />
        <ActionsSlots
          render={() => (
            <>
              {hasResourcePermissions('api_client_white_ip_addresses', [
                Permission.Full,
                Permission.Update,
              ]) && <AppListEditButton />}
            </>
          )}
        />
      </Datagrid>
    </AppList>
  );
};

export default ApiClientWhiteIpAddressesList;
