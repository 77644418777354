import { endOfMonth, endOfWeek, getTime, startOfMonth, startOfWeek, sub } from 'date-fns';

import { Shortcut } from '../DatePickerShortcuts';

export const DEFAULT_SHORTCUTS: Shortcut[] = [
  {
    label: 'Эта неделя',
    getValue: () => {
      const today = new Date();
      return [getTime(startOfWeek(today)), getTime(today)];
    },
  },
  {
    label: 'Прошлая неделя',
    getValue: () => {
      const today = new Date();
      const prevWeek = sub(today, { days: 7 });
      return [getTime(startOfWeek(prevWeek)), getTime(endOfWeek(prevWeek))];
    },
  },
  {
    label: 'Последние 7 дней',
    getValue: () => {
      const today = new Date();
      return [getTime(sub(today, { days: 7 })), getTime(today)];
    },
  },
  {
    label: 'Текущий месяц',
    getValue: () => {
      const today = new Date();
      return [getTime(startOfMonth(today)), getTime(today)];
    },
  },
  {
    label: 'Прошлый месяц',
    getValue: () => {
      const today = new Date();
      const prevMonth = sub(today, { months: 1 });
      return [getTime(startOfMonth(prevMonth)), getTime(endOfMonth(prevMonth))];
    },
  },
  {
    label: 'Последние 30 дней',
    getValue: () => {
      const today = new Date();
      return [getTime(sub(today, { days: 30 })), getTime(today)];
    },
  },
  {
    label: 'Последние 6 месяцев',
    getValue: () => {
      const today = new Date();
      return [getTime(sub(today, { months: 6 })), getTime(today)];
    },
  },
  { label: 'Сбросить', getValue: () => [null, null] },
];
