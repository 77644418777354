import {
  AutocompleteInputIntrospected,
  NumberInputIntrospected,
  TextInputIntrospected,
} from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { fieldWidth } from '../../themes/variables';
import { AppEditGuesser } from '../Common/Edit';

const ApiClientWhiteIpAddressesEdit = (): JSX.Element => {
  return (
    <AppEditGuesser
      actions={false}
      listName="Клиенты API – белые IP-адреса"
      mutationOptions={{ onError: () => undefined }}
      title="Редактирование белого IP адреса клиента API"
    >
      <ReferenceInput perPage={200} reference="api_clients" source="apiClient">
        <AutocompleteInputIntrospected
          filterToQuery={(searchText: string) => ({ name: searchText })}
          label="Клиент API"
          optionText="name"
          source="name"
          sx={fieldWidth.medium}
          variant="outlined"
        />
      </ReferenceInput>
      <TextInputIntrospected label="IP" source="ip" sx={fieldWidth.medium} variant="outlined" />
      <NumberInputIntrospected
        label="Маска"
        source="mask"
        sx={fieldWidth.medium}
        variant="outlined"
      />
    </AppEditGuesser>
  );
};

export default ApiClientWhiteIpAddressesEdit;
