import { Box } from '@mui/material';
import { AppBreadCrumbs } from 'components/Common/BreadCrumbs';
import { AppPanelHeader, AppPanelHeaderToolbar } from 'components/Common/PanelHeader';
import { Show, SimpleShowLayout, Tab, useGetOne, useShowController } from 'react-admin';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';

import OrdersShowCustomData from './OrdersShowCustomData';
import OrdersShowDisputes from './OrdersShowDisputes';
import { OrdersShowInfoLayout } from './OrdersShowInfoLayout';
import OrdersShowNotifications from './OrdersShowNotifications';
import OrdersShowRequestLogs from './OrdersShowRequestLogs';
import OrdersShowSendNotification from './OrdersShowSendNotification';
import { OrdersTabbedShowLayout } from './OrdersTabbedShowLayout';
import { usePermissions } from '../../../hooks/usePermissions';
import { Permission } from '../../../types';
import OrdersRefundChargeback from '../OrdersRefund/OrdersRefundChargeback';

export const OrdersShow = (): JSX.Element => {
  const { record } = useShowController();
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  const { data: notificationData, isLoading } = useGetOne('order_notification_urls', {
    id: record?.orderNotificationUrl || '',
  });

  const { data: orderStatus } = useGetOne(
    'order_statuses',
    {
      id: record?.status || '',
    },
    {
      enabled: !isLoading,
    },
  );

  return (
    <Box>
      <AppBreadCrumbs currentPage="Просмотр заказа" listName="Заказы" />
      <Show
        sx={{
          '& .RaShow-noActions': {
            marginTop: '8px',
          },
        }}
      >
        <AppPanelHeader pb={1} sx={{ backgroundColor: '#F9FAFB' }}>
          <AppPanelHeaderToolbar
            actions={
              hasResourceAccess('user_actions')
                ? [<UserActionsIconButton entityName="order" key="user-action-link" />]
                : undefined
            }
            titleText={`Заказ #${record?.originId || ''}`}
          />
        </AppPanelHeader>
        <SimpleShowLayout sx={{ padding: '0px 0px', backgroundColor: '#F9FAFB' }}>
          <OrdersTabbedShowLayout>
            <Tab label="Информация">
              <OrdersShowInfoLayout
                notificationUrl={notificationData?.notificationUrl}
                statusAlias={orderStatus?.alias}
              />
            </Tab>

            <Tab label="Дополнительные данные">
              <OrdersShowCustomData />
            </Tab>

            <Tab label="Уведомления">
              <OrdersShowNotifications />
              <OrdersShowSendNotification notificationUrl={notificationData?.notificationUrl} />
            </Tab>

            {hasResourceAccess('order_events') && (
              <Tab label="Логи запросов">
                <OrdersShowRequestLogs />
              </Tab>
            )}

            {hasResourcePermissions('orders', [
              Permission.Full,
              Permission.Update,
              Permission.UpdatePost,
            ]) && (
              <Tab label="Возврат">
                <OrdersRefundChargeback />
              </Tab>
            )}

            {hasResourceAccess('order_disputes') && (
              <Tab label="Диспуты">
                <OrdersShowDisputes />
              </Tab>
            )}
          </OrdersTabbedShowLayout>
        </SimpleShowLayout>
      </Show>
    </Box>
  );
};
