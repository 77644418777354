import { Box, styled } from '@mui/material';

export const PopoverCalendarContainerStyled = styled(Box)({
  display: 'flex',
  flexWrap: 'nowrap',
  overflow: 'hidden',
});

export const PopoverLayoutStyled = styled(Box)({
  backgroundColor: 'rgb(255, 255, 255)',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const PopoverListStyled = styled(Box)({
  maxWidth: '200px',
  padding: '28px 16px 8px 24px',
  boxSizing: 'border-box',
});

export const PopoverContentStyled = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const PopoverDatesStyled = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
});

export const PopoverTimesStyled = styled(Box)({
  width: '100%',
  padding: '0 15px 15px 15px',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
});
