import { styled } from '@mui/material/styles';

import { Datagrid } from '../../../../shared/react-admin/Datagrids';
import { getColumnStyle } from '../../../../utils';

export const StyledDatagrid = styled(Datagrid)({
  '& .column-originId': getColumnStyle(65),
  '& .column-ip': getColumnStyle(350),
  '& .column-mask': { width: '100%', maxWidth: '100%', minWidth: '100%' },
  '& .column-createdAt': getColumnStyle(120),
  '& .column-updatedAt': getColumnStyle(120),
});
