import { MerchantNotificationsEventNamesType } from 'config/requests';
import { Box } from 'shared/mui/Box';
import { Divider } from 'shared/mui/Divider/Divider';
import { BooleanInput } from 'shared/react-admin/Inputs';

type Props = MerchantNotificationsEventNamesType & {
  key?: string;
};
export const MerchantsShowNotificationsEvent: React.FC<Props> = ({ label, description, name }) => (
  <Box>
    <Box>{label}:</Box>
    <Divider sx={{ margin: '12px 0!important' }} />
    <BooleanInput label={description} source={`event.${name}`} />
  </Box>
);
