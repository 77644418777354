import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { FunctionField } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';
import { MerchantAccount } from 'types';

import { EMPTY_FIELD } from '../../../../../../constants';

type Props = {
  label: string;
  source?: string;
};
type Colors = 'red' | 'orange' | 'green';
type Field = 'icon' | 'text';

const colorsMap: Record<Colors, Record<Field, string>> = {
  green: { icon: appColors.success.light, text: appColors.success.main },
  orange: { icon: appColors.warning.light, text: appColors.warning.main },
  red: { icon: appColors.error.light, text: appColors.error.main },
};

const getColor = (field: Field, color?: Colors) =>
  color ? colorsMap[color][field] : appColors.text.disabled;

export const MerchantShowAccountsActivityField: React.FC<Props> = ({ label }) => {
  return (
    <FunctionField
      label={label}
      render={({ balanceUpdatedAtColor, balanceUpdatedAtMessage }: MerchantAccount) => {
        return (
          <Stack alignItems="start" direction="row" gap={1}>
            <MonitorHeartOutlinedIcon htmlColor={getColor('icon', balanceUpdatedAtColor)} />
            <Typography color={getColor('text', balanceUpdatedAtColor)} component="span">
              {balanceUpdatedAtMessage || EMPTY_FIELD}
            </Typography>
          </Stack>
        );
      }}
    />
  );
};
