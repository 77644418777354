import { ListAlt } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export const OrdersFilterGroupByIdInputButton = ({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: () => void;
}) => {
  return (
    <IconButton disabled={disabled} onClick={onClick} sx={{ padding: '4px' }}>
      <ListAlt color="primary" />
    </IconButton>
  );
};
