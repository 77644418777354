import { Typography } from '@mui/material';
import { Datagrid, ReferenceManyField, TextField } from 'react-admin';
import { DateTimeField } from 'shared/react-admin/Fields';

import { OrdersShowTableCard } from './OrdersShowTableCard';
import { usePermissions } from '../../../hooks/usePermissions';
import { AppReferenceField } from '../../Common';

export const OrdersShowStatuses = (): JSX.Element => {
  const { hasResourceAccess } = usePermissions();

  return (
    <>
      {hasResourceAccess('order_status_histories') && (
        <OrdersShowTableCard>
          <Typography component="h2" fontWeight={500} mb={2} ml={2} mt={2} variant="h6">
            Статусы
          </Typography>
          <ReferenceManyField label={false} reference="order_status_histories" target="order">
            <Datagrid
              bulkActionButtons={false}
              sx={{
                border: 'none',
                '& .MuiTableCell-root': { padding: '16px' },
              }}
            >
              <TextField label="ID" source="originId" />
              <DateTimeField label="Время изменения" source="createdAt" />
              <AppReferenceField label="Статус" reference="new/order_statuses" source="statusNew">
                <TextField source="description" />
              </AppReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </OrdersShowTableCard>
      )}
    </>
  );
};
