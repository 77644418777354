import { EMPTY_FIELD } from 'constants/emptyField';

import { useEffect } from 'react';

import { TablePagination } from '@mui/material';
import { useLocalDatagrid } from 'hooks/useLocalDatagrid';
import { useLocalDatagridPagination } from 'hooks/useLocalDatagridPagination';
import { usePermissions } from 'hooks/usePermissions';
import {
  Datagrid,
  FunctionField,
  NumberField,
  RaRecord,
  ReferenceArrayField,
  TextField,
  useListContext,
  useRemoveFromStore,
} from 'react-admin';
import { DateTimeField, OrderStatusChipField } from 'shared/react-admin/Fields';
import { ReferenceLink } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { Order, OrderDispute, Permission } from 'types';

import { OrdersListBulkActions } from './OrdersListBulkAction';
import { AppReferenceField, AppReferenceOneField } from '../../Common';
import { AppListShowButton } from '../../Common/Buttons';
import { OrderDisputeField } from '../components/OrderDispute/OrderDisputeField';

type Props = {
  orders: Order[];
  total: number;
  isLoading: boolean;
  setSelectedOrder: (order: Order) => void;
  setIsOpenDispute: (open: boolean) => void;
  setSelectedDispute: (dispute: OrderDispute | undefined) => void;
};

const Сolors: Record<'green' | 'red', string> = {
  green: 'rgba(76, 175, 80, 0.10)',
  red: 'rgba(255, 23, 68, 0.10)',
};

const СolorConfig: Record<string, string> = {
  '/admin/new/order_statuses/success': Сolors.green,
  '/admin/new/order_statuses/failure': Сolors.red,
  '/admin/new/order_statuses/expired': Сolors.red,
  '/admin/new/order_statuses/cancelled': Сolors.red,
};

const getRowStyle = (record: Order) =>
  record?.statusNew && СolorConfig[record.statusNew]
    ? { background: СolorConfig[record.statusNew] }
    : undefined;

export function OrdersListLocalDatagrid({
  total,
  isLoading,
  orders = [],
  setSelectedOrder,
  setIsOpenDispute,
  setSelectedDispute,
}: Props): JSX.Element {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  const { resource } = useListContext();
  const remove = useRemoveFromStore();

  const { sortedData, sort, setSort } = useLocalDatagrid({
    data: orders,
    defaultSort: {
      field: 'createdAt',
      order: 'ASC',
    },
  });

  const { dataPerPage, ...paginationProps } = useLocalDatagridPagination<Order>({
    data: sortedData,
    defaultPage: 0,
    defaultRowsPerPage: 10,
  });

  useEffect(() => {
    return () => {
      remove(`${resource}.selectedIds`);
      remove(`${resource}.listParams`);
    };
  }, []);

  return (
    <>
      <Datagrid
        bulkActionButtons={
          hasResourceAccess('order_disputes') && (
            <OrdersListBulkActions
              setIsOpen={setIsOpenDispute}
              setSelectedDispute={setSelectedDispute}
              setSelectedOrder={setSelectedOrder}
            />
          )
        }
        data={dataPerPage}
        isLoading={isLoading}
        rowStyle={getRowStyle}
        setSort={setSort}
        sort={sort}
        sx={{ width: '100%' }}
      >
        <TextField label="ID" sortBy="id" source="originId" />
        <DateTimeField label="Время создания" source="createdAt" />
        <NumberField label="Сумма" options={{ minimumFractionDigits: 2 }} source="amount" />
        {hasResourceAccess('currencies') && (
          <AppReferenceOneField
            label="Валюта"
            link={false}
            reference="currencies"
            sortable={false}
            source="currency"
            target="id"
          >
            <FunctionField
              render={(record: RaRecord | undefined) => `${record?.alphaCode}, ${record?.name}`}
            />
          </AppReferenceOneField>
        )}
        {hasResourceAccess('merchants') && (
          <AppReferenceOneField
            label="Мерчант"
            link={false}
            reference="merchants"
            sortable={false}
            source="merchant"
            target="id"
          >
            {hasResourcePermissions('merchants', [Permission.Read, Permission.Full]) ? (
              <ReferenceLink type="show">
                <TextField source="name" />
              </ReferenceLink>
            ) : (
              <TextField source="name" />
            )}
          </AppReferenceOneField>
        )}
        {hasResourceAccess('gateways') && (
          <AppReferenceOneField
            label="Шлюз"
            link={false}
            reference="gateways"
            sortable={false}
            source="gateway"
            target="id"
          >
            {hasResourcePermissions('merchants', [Permission.Read, Permission.Full]) ? (
              <ReferenceLink type="show">
                <TextField source="name" />
              </ReferenceLink>
            ) : (
              <TextField source="name" />
            )}
          </AppReferenceOneField>
        )}
        <AppReferenceField label="Направление" reference="directions" source="direction">
          <TextField source="description" />
        </AppReferenceField>
        <AppReferenceField
          label="Статус"
          reference="new/order_statuses"
          sortable={false}
          source="statusNew"
        >
          <OrderStatusChipField source="description" />
        </AppReferenceField>
        {hasResourceAccess('order_disputes') && (
          <ReferenceArrayField
            label="Диспут"
            reference="order_disputes"
            sortable={false}
            source="orderDisputes"
          >
            <OrderDisputeField />
          </ReferenceArrayField>
        )}
        <TextField label="Id у мерчанта" source="merchantReference" />
        <FunctionField
          label="ID у провайдера"
          render={(record: RaRecord | undefined) => {
            return record?.providerReferenceId || EMPTY_FIELD;
          }}
        />
        <ActionsSlots render={() => <AppListShowButton target="_blank" />} />
      </Datagrid>
      {total > 0 && <TablePagination count={total} {...paginationProps} />}
    </>
  );
}
