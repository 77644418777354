import React from 'react';

import { Chip, List } from '@mui/material';

import { DEFAULT_SHORTCUTS } from './constants/shortcuts';
import { ListItemStyled } from './DatePickerShortcuts.styled';

export type Shortcut = { label: string; getValue: () => [number | null, number | null] };

export type ShortcutsListProps = {
  shortcuts?: Shortcut[];
  onClick?: (value: [number | null, number | null]) => void;
};

export const DatePickerShortcuts: React.FC<ShortcutsListProps> = ({
  onClick,
  shortcuts = DEFAULT_SHORTCUTS,
}) => {
  return (
    <List>
      {shortcuts.map((shortcut) => (
        <ListItemStyled key={shortcut.label}>
          <Chip
            label={shortcut.label}
            onClick={() => {
              if (!onClick) {
                return;
              }
              onClick(shortcut.getValue());
            }}
          />
        </ListItemStyled>
      ))}
    </List>
  );
};
