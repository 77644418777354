import { BooleanInput, TextField } from 'react-admin';
import { AutocompleteInputIntrospected } from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { fieldWidth } from '../../themes/variables';
import { AppEditGuesser } from '../Common/Edit';

const ApiKeysEdit = (): JSX.Element => {
  return (
    <AppEditGuesser actions={false} listName="Ключи API" title="Редактирование ключа API">
      <TextField label="Id" source="originId" />
      <ReferenceInput perPage={200} reference="api_clients" source="apiClient">
        <AutocompleteInputIntrospected
          filterToQuery={(searchText: string) => ({ name: searchText })}
          label="Api клиент"
          optionText="name"
          source="name"
          sx={fieldWidth.medium}
          variant="outlined"
        />
      </ReferenceInput>
      <TextField label="Значение" source="value" />
      <BooleanInput label="Включен" source="active" />
    </AppEditGuesser>
  );
};

export default ApiKeysEdit;
