import React from 'react';

import { Box, Button, TextField, Typography } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { useRefresh } from 'react-admin';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';

import { createDispute } from '../../../../config/requests';
import { Order } from '../../../../types';
import { AppDialogActions, AppDialogBase, AppDialogHeader } from '../../../Common/Dialog';

type Props = {
  order: Order | undefined;
  onClose: () => void;
} & DialogProps;
const OrdersDisputeCreateDialog = ({ order, onClose, open }: Props): JSX.Element => {
  const form = useForm();
  const refresh = useRefresh();

  if (!order) {
    return <></>;
  }
  const handleSubmit = () => {
    createDispute({
      ...form.getValues(),
      openedAt: form.getValues('openedAt') / 1000,
      order: order.id,
    }).then(() => {
      refresh();
      onClose();
    });
  };

  return (
    <AppDialogBase onClose={onClose} open={open}>
      <form {...form}>
        <AppDialogHeader onClose={onClose} title="Открыть диспут" />
        <Typography mb={2}>По заказу #{order?.originId || ''} будет открыт диспут.</Typography>
        <Controller
          control={form.control}
          name="openedAt"
          render={({ field, fieldState }) => (
            <DatePicker
              customInput={
                <TextField
                  InputLabelProps={{ shrink: true }}
                  error={!!fieldState.error}
                  fullWidth
                  helperText={fieldState.error?.message}
                  label="Дата"
                  size="small"
                  value={field.value}
                  variant="outlined"
                />
              }
              dateFormat="dd/MM/yyyy"
              locale="ru"
              onChange={(date) => {
                field.onChange(date?.getTime());
              }}
              selected={field.value}
              selectsStart
              startDate={field.value}
            />
          )}
          rules={{
            required: 'Значение не должно быть пустым',
          }}
        />
        <Controller
          control={form.control}
          name="openReason"
          render={({ field, fieldState }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              error={!!fieldState.error}
              fullWidth
              helperText={fieldState.error?.message}
              label="Причина"
              multiline
              onChange={(value) => {
                field.onChange(value);
              }}
              rows={4}
              size="small"
              value={field.value}
              variant="outlined"
            />
          )}
          rules={{
            required: 'Значение не должно быть пустым',
            maxLength: {
              value: 255,
              message: 'Значение слишком длинное. Должно быть равно 255 символам или меньше.',
            },
          }}
        />
        <AppDialogActions>
          <Box display="flex" gap={2}>
            <Button onClick={form.handleSubmit(handleSubmit)} variant="contained">
              Подтвердить
            </Button>
            <Button onClick={onClose} variant="outlined">
              Отменить
            </Button>
          </Box>
        </AppDialogActions>
      </form>
    </AppDialogBase>
  );
};

export default OrdersDisputeCreateDialog;
