import { styled } from '@mui/material/styles';
import { Pagination } from 'react-admin';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

const commonStyle = getColumnStyle();

export const StyledDatagrid = styled(Datagrid)({
  '& .column-id': getColumnStyle(80),
  '& .column-name': { maxWidth: '500px' },
  '& .column-createdAt': commonStyle,
  '& .column-updatedAt': commonStyle,
  '& .RaDatagrid-headerCell:last-of-type': getColumnStyle(150),
  '& .RaDatagrid-rowCell': { padding: '16px' },
});

export const StyledPagination = styled(Pagination)({
  display: 'block',
  marginBottom: '24px',
});
