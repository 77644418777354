import { styled } from '@mui/material/styles';

import { Card } from '../../../../shared/mui/Card';
import { Datagrid } from '../../../../shared/react-admin/Datagrids';
import { getColumnStyle } from '../../../../utils';

export const CardStyled = styled(Card)({
  width: '100%',
  background: 'transparent',
});

export const StyledDatagrid = styled(Datagrid)({
  '& .column-name': getColumnStyle(200),
  '& .column-provider': getColumnStyle(120),
  '& .column-comment': getColumnStyle(200),
  '& .column-credentials': getColumnStyle(160),
  '& .column-createdAt': getColumnStyle(120),
  '& .column-updatedAt': getColumnStyle(120),
});
