import { useState } from 'react';

import { useNotify, useRefresh } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { SaveButton } from 'shared/react-admin/Buttons';
import { CreateDialog } from 'shared/react-admin/Dialogs/CreateDialog/CreateDialog';

import { MethodLogosCreateForm } from './MethodLogosCreateForm';
import { sendLogo } from '../../../config/requests';

type MethodLogosCreateFormValues = {
  file?: FileList;
  filePath: string;
  name: string;
};

export const MethodLogosCreate: React.FC = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const refresh = useRefresh();
  const [errors, setErrors] = useState<any>();
  const form = useForm({
    defaultValues: {
      name: '',
      filePath: '',
      file: undefined,
    },
  });

  const submitForm = (data: MethodLogosCreateFormValues) => {
    if (data?.file?.[0]) {
      const formData = new FormData();
      formData.append('file', data.file[0]);
      formData.append('name', data.name);

      sendLogo(formData)
        .then(() => {
          notify('Логотип создан!', { type: 'info' });
          refresh();
          navigate(-1);
        })
        .catch((e) => setErrors(e?.data));
    } else {
      notify('Загрузите логотип!', { type: 'error' });
    }
  };

  return (
    <CreateDialog title="Загрузка логотипа">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(submitForm)}>
          <MethodLogosCreateForm errors={errors} />
          <CardActionsButtons
            leftActionsSlot={[
              <SaveButton key="save-button" label="Загрузить" type="submit" />,
              <CancelButton key="method-logos-cancel" linkType="back" variant="outlined" />,
            ]}
          />
        </form>
      </FormProvider>
    </CreateDialog>
  );
};
