import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { activateUsers, deactivateUsers } from 'config/requests';
import { BulkActionsToolbarProps, Identifier, useNotify, useRefresh } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { getId } from 'utils';

export const UsersListBulkActionsToolbar: React.FC = ({ selectedIds }: BulkActionsToolbarProps) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = async (callback: (userIds?: Identifier[]) => Promise<unknown>) => {
    try {
      await callback(selectedIds?.map((id) => Number(getId(id))));
      refresh();
      notify('Данные успешно обновлены');
    } catch (e: unknown) {
      notify('Ошибка обновления данных');
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Button
        label="Активировать"
        onClick={() => handleClick(activateUsers)}
        startIcon={<CheckCircleOutlineIcon />}
        sx={{
          marginRight: '8px',
        }}
      />
      <Button
        label="Деактивировать"
        onClick={() => handleClick(deactivateUsers)}
        startIcon={<DoDisturbIcon />}
      />
    </Box>
  );
};
