import ContentAdd from '@mui/icons-material/Add';
import { useResourceContext, useCreatePath } from 'react-admin';
import { Link } from 'react-router-dom';

import { Button, ButtonProps } from '../..';
import { useCreateEntityFilterQueryString } from '../../../../../hooks/useCreateEntityFilterQueryString';

export type CreateButtonToEntityProps = ButtonProps & {
  entity?: string;
};

export function CreateButtonToEntity({
  entity = '',
  label = 'Добавить',
  variant = 'contained',
  size = 'medium',
  startIcon = <ContentAdd />,
  ...restProps
}: CreateButtonToEntityProps): JSX.Element {
  const resource = useResourceContext();
  const createPath = useCreatePath();
  const { createEntityFilterQueryString } = useCreateEntityFilterQueryString();

  const search = createEntityFilterQueryString({ entity });

  return (
    <Button
      component={Link}
      label={label}
      size={size}
      startIcon={startIcon}
      to={{
        pathname: createPath({ resource, type: 'create' }),
        search,
      }}
      variant={variant}
      {...restProps}
    />
  );
}
