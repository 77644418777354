import { AppReferenceOneField } from 'components/Common';
import { BooleanField, TextField } from 'react-admin';

import { AppShowGuesser } from '../Common/Show';

const ApiKeysShow = (): JSX.Element => (
  <AppShowGuesser listName="Ключи API" title="Просмотр ключа API">
    <TextField label="Id" source="originId" />
    <AppReferenceOneField label="Api клиент" reference="api_clients" source="apiClient" target="id">
      <TextField source="name" />
    </AppReferenceOneField>
    <TextField label="Значение" source="value" />
    <BooleanField label="Включен" source="active" />
  </AppShowGuesser>
);

export default ApiKeysShow;
