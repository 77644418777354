import { useCallback, useState } from 'react';

import GavelIcon from '@mui/icons-material/Gavel';
import { Button, Card, Table, TableRow } from '@mui/material';
import {
  RecordContextProvider,
  ReferenceField,
  TextField,
  useDelete,
  useGetOne,
  useRecordContext,
  useRefresh,
} from 'react-admin';

import { Order } from '../../../types';
import { AppDateField } from '../../Common/Fields/AppDateField';
import { AppNewShowHeadingTableCell, AppNewShowTableCell } from '../../Common/Show';
import { AppTypography } from '../../Common/Typography';
import OrdersDisputeEditDialog from '../components/OrderDispute/OrdersDisputeEditDialog';

type Props = {
  orderDisputeId: string;
};
const OrdersShowDisputeInfo = ({ orderDisputeId }: Props): JSX.Element => {
  const record = useRecordContext<Order>();
  const refresh = useRefresh();
  const [isOpen, setIsOpen] = useState(false);
  const { data: orderDispute, isLoading } = useGetOne('order_disputes', {
    id: orderDisputeId || '',
  });

  const { data: orderStatus } = useGetOne(
    'order_dispute_statuses',
    { id: orderDispute?.status },
    { enabled: !isLoading },
  );

  const [deleteOne] = useDelete(
    'order_disputes',
    {
      id: orderDispute?.id || '',
    },
    { onSuccess: () => refresh() },
  );

  const handleDelete = useCallback(() => deleteOne(), [deleteOne]);

  if (!orderDispute) {
    return <></>;
  }

  return (
    <Card
      sx={{
        width: '600px',
        boxShadow: 'none',
        border: '1px solid rgba(0,0,0,0.12)',
        marginBottom: '24px',
        '& .MuiTable-root': {
          borderCollapse: 'unset',
        },
      }}
    >
      <OrdersDisputeEditDialog
        dispute={orderDispute}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        order={record}
      />
      <AppTypography mb={2} ml={3} mt={2} size="large">
        Диспут #{orderDispute?.originId}
      </AppTypography>
      <RecordContextProvider value={orderDispute}>
        <Table sx={{ borderTop: '1px solid rgba(0,0,0,0.12)' }}>
          <TableRow>
            <AppNewShowHeadingTableCell>ID</AppNewShowHeadingTableCell>
            <AppNewShowTableCell>
              <TextField source="originId" />
            </AppNewShowTableCell>
          </TableRow>
          <TableRow>
            <AppNewShowHeadingTableCell>Статус диспута</AppNewShowHeadingTableCell>
            <AppNewShowTableCell>
              <ReferenceField reference="order_dispute_statuses" source="status">
                <TextField source="name" />
              </ReferenceField>
            </AppNewShowTableCell>
          </TableRow>
          <TableRow>
            <AppNewShowHeadingTableCell>Дата открытия</AppNewShowHeadingTableCell>
            <AppNewShowTableCell>
              <AppDateField source="openedAt" />
            </AppNewShowTableCell>
          </TableRow>
          <TableRow>
            <AppNewShowHeadingTableCell>Причина открытия</AppNewShowHeadingTableCell>
            <AppNewShowTableCell>
              <TextField emptyText="—" source="openReason" />
            </AppNewShowTableCell>
          </TableRow>
          <TableRow>
            <AppNewShowHeadingTableCell>Дата закрытия</AppNewShowHeadingTableCell>
            <AppNewShowTableCell>
              <AppDateField source="closedAt" />
            </AppNewShowTableCell>
          </TableRow>
          <TableRow>
            <AppNewShowHeadingTableCell>Причина закрытия</AppNewShowHeadingTableCell>
            <AppNewShowTableCell>
              <TextField emptyText="—" source="closeReason" />
            </AppNewShowTableCell>
          </TableRow>
        </Table>
      </RecordContextProvider>
      {orderStatus?.alias === 'received' && (
        <Button
          onClick={() => setIsOpen(true)}
          startIcon={<GavelIcon />}
          sx={{ margin: '24px' }}
          variant="contained"
        >
          Закрыть диспут
        </Button>
      )}
      {orderStatus?.alias === 'rejected' && (
        <Button color="error" onClick={handleDelete} sx={{ margin: '24px' }} variant="outlined">
          Удалить диспут
        </Button>
      )}
    </Card>
  );
};

export default OrdersShowDisputeInfo;
