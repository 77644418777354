import { useFormContext } from 'react-hook-form';
import { useFilterContext } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterAutocompleteChip,
  FilterMultiselectChip,
} from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import { AutocompleteInputShrink, AutocompleteMultiselectInput } from 'shared/react-admin/Inputs';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { cleanEmpty } from 'utils/cleanEmpty';

export const OrderConversionListFilterChips: React.FC = () => {
  const { resetField, setValue, watch } = useFormContext();
  const { setAppliedFilter, appliedFilters } = useFilterContext();

  const merchantWatched = watch('merchant');
  const providerWatched = watch('provider');

  return (
    <>
      <FilterAutocompleteChip
        label="Мерчант"
        name="merchant"
        onApply={() => {
          setAppliedFilter('provider', []);
          setAppliedFilter('gateway', []);
        }}
        onDelete={() => {
          resetField('provider', { keepDirty: true });
          resetField('gateway', { keepDirty: true });
          setAppliedFilter('provider', []);
          setAppliedFilter('gateway', []);
        }}
        onReset={() => {
          setValue('provider', appliedFilters?.['provider']);
          setValue('gateway', appliedFilters?.['gateway']);

          setAppliedFilter('provider', appliedFilters?.['provider']);
          setAppliedFilter('gateway', appliedFilters?.['gateway']);
        }}
        resource="merchants"
      >
        <ReferenceInput perPage={100} reference="merchants" source="merchant">
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            helperText={false}
            label="Мерчант"
            optionText="name"
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterMultiselectChip
        label="Провайдер"
        name="provider"
        onApply={() => {
          setAppliedFilter('gateway', []);
        }}
        onDelete={() => {
          resetField('gateway', { keepDirty: true });
          setAppliedFilter('gateway', []);
        }}
        onReset={() => {
          setValue('gateway', appliedFilters?.['gateway']);

          setAppliedFilter('gateway', appliedFilters?.['gateway']);
        }}
        resource="providers"
      >
        <ReferenceArrayInput
          filter={cleanEmpty({ merchantAccounts: { merchants: merchantWatched } })}
          name="provider"
          perPage={100}
          reference="providers"
          source="provider"
        >
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Провайдер"
            limitTags={1}
            name="provider"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterMultiselectChip label="Шлюзы" limitTags={1} name="gateway" resource="gateways">
        <ReferenceArrayInput
          filter={cleanEmpty({
            merchantAccounts: { merchants: merchantWatched },
            provider: providerWatched,
          })}
          name="gateway"
          perPage={100}
          reference="gateways"
          source="gateway"
        >
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Шлюзы"
            limitTags={1}
            name="gateway"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
    </>
  );
};
