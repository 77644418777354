import { styled } from '@mui/material/styles';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

const commonStyle = getColumnStyle();

export const StyledDatagrid = styled(Datagrid)({
  '& .column-name': commonStyle,
  '& .column-provider': commonStyle,
  '& .column-createdAt': commonStyle,
  '& .column-updatedAt': commonStyle,
});
