import _ from 'lodash';
import { useFormContext } from 'react-hook-form';

import { RowStyled } from './FilterFromToChip.styled';
import { Chip } from '../../../../Chips';
import { useAnchor } from '../../../../DatePicker/hooks/useAnchor';
import { useFilterContext } from '../../../context/filterContext';
import { PopoverChip } from '../components/PopoverChip/PopoverChip';

export type FilterFromToChipProps = {
  label: string;
  fromName: string;
  toName: string;
  fromSlot: React.ReactNode;
  toSlot: React.ReactNode;
};

export const FilterFromToChip: React.FC<FilterFromToChipProps> = ({
  fromSlot,
  toSlot,
  fromName,
  label,
  toName,
}) => {
  const { handleClose, handleClick, anchorEl } = useAnchor<HTMLDivElement>();

  const { appliedFilters, setAppliedFilter } = useFilterContext();
  const { resetField, clearErrors, getValues, setValue } = useFormContext();

  const valueFrom = _.get(appliedFilters, fromName);
  const valueTo = _.get(appliedFilters, toName);

  if (!valueFrom && !valueTo) {
    return <></>;
  }

  function onApply() {
    setAppliedFilter(fromName, getValues(fromName));
    setAppliedFilter(toName, getValues(toName));
  }

  function onReset() {
    setAppliedFilter(fromName, _.get(appliedFilters, fromName));
    setAppliedFilter(toName, _.get(appliedFilters, toName));
    setValue(fromName, _.get(appliedFilters, fromName));
    setValue(toName, _.get(appliedFilters, toName));
    clearErrors(fromName);
    clearErrors(toName);
  }

  const fromString = valueFrom ? `от ${valueFrom}` : '';
  const toString = valueTo ? ` до ${valueTo}` : '';

  const open = Boolean(anchorEl);

  return (
    <>
      <PopoverChip
        anchorEl={anchorEl}
        onApply={onApply}
        onClose={handleClose}
        onReset={onReset}
        open={open}
      >
        <RowStyled>
          {fromSlot} {toSlot}
        </RowStyled>
      </PopoverChip>
      <Chip
        label={`${label}: ${fromString}${toString}`}
        onClick={handleClick}
        onDelete={() => {
          resetField(fromName);
          resetField(toName);
          setAppliedFilter(fromName, undefined);
          setAppliedFilter(toName, undefined);
        }}
        size="medium"
      />
    </>
  );
};
