import React from 'react';

import { Autocomplete, Chip, TextField } from '@mui/material';
import { TextInput, TextInputProps, useInput } from 'react-admin';

type Props = TextInputProps & { resetFilters: () => void };

export function OrdersFilterGroupByIdInput(props: Props): JSX.Element {
  const { field } = useInput(props);
  const { resetFilters, ...restProps } = props;

  if (Array.isArray(field.value) && field.value?.length) {
    return (
      <Autocomplete
        disableClearable={true}
        freeSolo
        multiple
        options={[]}
        renderInput={(params) => (
          <TextField
            {...params}
            {...props}
            InputProps={{ ...params.InputProps, ...props.InputProps }}
            sx={{
              '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                padding: '4px 14px',
              },
            }}
          />
        )}
        renderTags={(value: string[]) => {
          const firstId = value[0];
          const restIdsAmount = value.length - 1;
          return (
            <>
              <Chip
                label={firstId}
                onDelete={() => resetFilters()}
                size="small"
                sx={{ marginRight: 1, maxWidth: 100 }}
              />
              {value.length > 1 && <Chip label={`+${restIdsAmount}`} size="small" />}
            </>
          );
        }}
        value={field.value}
      />
    );
  }
  return <TextInput {...restProps}></TextInput>;
}
