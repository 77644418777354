import { useCallback, useState } from 'react';

import { Typography } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { deleteRecord } from 'config/requests';
import { RaRecord, useNotify, useRefresh } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

import { StyledBox } from './DeleteDialog.styled';

export type DeleteDialogProps = {
  alert?: JSX.Element;
  record?: RaRecord;
  title: string;
  details?: string;
  onClose: () => void;
  onSuccess?: () => void;
  onDelete?: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
} & DialogProps;

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  alert,
  open,
  onClose,
  onCancel,
  onDelete,
  record,
  title,
  details,
  onSuccess,
  children,
  isLoading,
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [isLoadingData, setIsLoadingData] = useState(false);

  const deleteOneRecord = useCallback(() => {
    if (onDelete) {
      onDelete();

      return;
    }
    setIsLoadingData(true);
    deleteRecord(record?.id.toString() || '')
      .then(() => {
        refresh();
        onClose();
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error: any) => {
        const errorDetail = error?.data?.detail;
        if (errorDetail) {
          notify(errorDetail, { type: 'error', multiLine: true });
          return;
        }

        notify('Неизвестная ошибка', { type: 'error', multiLine: true });
      })
      .finally(() => setIsLoadingData(false));
  }, [record, onDelete]);

  return (
    <Dialog onClose={onClose} open={open}>
      <StyledBox>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title={title}
        />
        {children || (
          <Box sx={{ margin: '0 24px' }}>
            {alert && alert}
            {details && (
              <Typography sx={{ padding: '16px 0' }} variant="body1">
                {details}
              </Typography>
            )}
            <CardActionsButtons
              rightActionsSlot={[
                <CancelButton
                  key="cancel-button"
                  onClick={onCancel || onClose}
                  variant="outlined"
                />,
                <DeleteButton
                  disabled={isLoading || isLoadingData}
                  key="delete-base-button"
                  onClick={deleteOneRecord}
                />,
              ]}
              sx={{ padding: '24px 0' }}
            />
          </Box>
        )}
      </StyledBox>
    </Dialog>
  );
};
