import { useState } from 'react';

import { OrdersFilterGroupByIdInput } from 'components/Orders/OrdersFilter/OrdersFilterGroupById/OrdersFilterGroupByIdInput';
import { OrdersFilterSearchByIdDialog } from 'components/Orders/OrdersFilter/OrdersFilterSearchByIdDialog/OrdersFilterSearchByIdDialog';
import { useFormContext } from 'react-hook-form';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { getIdInputValidate } from 'utils';

import { OrdersFilterGroupByIdInputButton } from './OrdersFilterGroupByIdInputButton';

type OrdersIdsFilterProps = {
  isIdFiltersDisabled: boolean;
  resetFilters: () => void;
};

export const OrdersFilterGroupById: React.FC<OrdersIdsFilterProps> = ({
  isIdFiltersDisabled,
  resetFilters,
}) => {
  const { watch } = useFormContext();
  const [currentIdFilter, setCurrentIdFilter] = useState<
    { field: string; label: string } | undefined
  >();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [id, merchantReference, providerReferenceId] = watch([
    'id',
    'merchantReference',
    'providerReferenceId',
  ]);

  const checkDisabled = (field: string | string[] | undefined): boolean => {
    if (!id && !merchantReference && !providerReferenceId) {
      return false;
    }

    return !field;
  };

  return (
    <>
      <FilterFieldGroup>
        <OrdersFilterGroupByIdInput
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <OrdersFilterGroupByIdInputButton
                disabled={isIdFiltersDisabled || checkDisabled(id)}
                onClick={() => {
                  setCurrentIdFilter({ field: 'id', label: 'ID в Kubera' });
                  setIsDialogOpen(true);
                }}
              />
            ),
          }}
          disabled={isIdFiltersDisabled || checkDisabled(id)}
          fullWidth
          helperText={false}
          label="ID в Kubera"
          name="id"
          resetFilters={resetFilters}
          resettable={true}
          source="id"
          validate={getIdInputValidate()}
          variant="outlined"
        />
        <OrdersFilterGroupByIdInput
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <OrdersFilterGroupByIdInputButton
                disabled={isIdFiltersDisabled || checkDisabled(merchantReference)}
                onClick={() => {
                  setCurrentIdFilter({ field: 'merchantReference', label: 'ID у мерчанта' });
                  setIsDialogOpen(true);
                }}
              />
            ),
          }}
          disabled={isIdFiltersDisabled || checkDisabled(merchantReference)}
          fullWidth
          helperText={false}
          label="ID у мерчанта"
          name="merchantReference"
          resetFilters={resetFilters}
          resettable={true}
          source="merchantReference"
          validate={getIdInputValidate()}
          variant="outlined"
        />
        <OrdersFilterGroupByIdInput
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <OrdersFilterGroupByIdInputButton
                disabled={isIdFiltersDisabled || checkDisabled(providerReferenceId)}
                onClick={() => {
                  setCurrentIdFilter({ field: 'providerReferenceId', label: 'ID у провайдера' });
                  setIsDialogOpen(true);
                }}
              />
            ),
          }}
          disabled={isIdFiltersDisabled || checkDisabled(providerReferenceId)}
          fullWidth
          helperText={false}
          label="ID у провайдера"
          name="providerReferenceId"
          resetFilters={resetFilters}
          resettable={true}
          source="providerReferenceId"
          validate={getIdInputValidate()}
          variant="outlined"
        />
      </FilterFieldGroup>
      {currentIdFilter && (
        <OrdersFilterSearchByIdDialog
          currentIdFilter={currentIdFilter}
          onClose={() => {
            setCurrentIdFilter(undefined);
            setIsDialogOpen(false);
          }}
          open={isDialogOpen}
          resetFilters={resetFilters}
        />
      )}
    </>
  );
};
