import { createContext, useContext } from 'react';

type OrdersFilterContextData = {
  notFoundOrderIds?: string[];
};

const OrdersFilterContext = createContext<OrdersFilterContextData | null>(null);
export const OrdersFilterContextProvider = OrdersFilterContext.Provider;

export function useOrdersFilterContext(): OrdersFilterContextData {
  const context = useContext(OrdersFilterContext);

  if (!context) {
    throw new Error(
      'Can not `useOrdersFilterContext` outside of the `OrdersFilterContextProvider`',
    );
  }

  return context;
}
