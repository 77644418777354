import { formatToMask } from './formatToMask';
import { DEFAULT_SEPARATOR, DEFAULT_DATE_MASK } from '../config/defaultValues';

export function fromTimestampRangeToString(
  date: [number | null, number | null],
  mask: string = DEFAULT_DATE_MASK,
): string {
  const [start, end] = date;

  const placeholder = mask.replaceAll(/([a-zA-Z])/g, '_');

  const formatStart = start === null ? placeholder : formatToMask(start, mask);
  const formatEnd = end === null ? placeholder : formatToMask(end, mask);

  return `${formatStart}${DEFAULT_SEPARATOR}${formatEnd}`;
}
