import { useGetOne } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { Chip } from '../../../../Chips';
import { useAnchor } from '../../../../DatePicker/hooks/useAnchor';
import { useFilterContext } from '../../../context/filterContext';
import { PopoverChip } from '../components/PopoverChip/PopoverChip';

export type FilterMultiselectChipProps = {
  label: string;
  field?: string;
  resource: string;
  name: string;
  children: React.ReactNode;
  onDelete?: VoidFunction;
  onApply?: VoidFunction;
  onReset?: VoidFunction;
};

export const FilterSelectChip: React.FC<FilterMultiselectChipProps> = ({
  field = 'name',
  resource,
  name,
  label,
  onApply,
  children,
  onDelete,
  onReset,
}) => {
  const { resetField, getValues, setValue } = useFormContext();
  const { setAppliedFilter, appliedFilters } = useFilterContext();
  const { handleClose, handleClick, anchorEl } = useAnchor<HTMLDivElement>();

  const { data } = useGetOne(
    resource,
    { id: appliedFilters[name] },
    { enabled: Boolean(appliedFilters[name]), keepPreviousData: true },
  );

  if (!appliedFilters[name] || !appliedFilters[name].length || !data?.[field]) {
    return <></>;
  }

  function onApplyHandler() {
    handleClose();
    onApply && onApply();
    setAppliedFilter(name, getValues(name));
  }

  function onResetHandler() {
    onReset && onReset();
    setAppliedFilter(name, appliedFilters?.[name]);
    setValue(name, appliedFilters?.[name]);
  }
  const open = Boolean(anchorEl);

  return (
    <>
      <PopoverChip
        anchorEl={anchorEl}
        onApply={onApplyHandler}
        onClose={handleClose}
        onReset={onResetHandler}
        open={open}
      >
        {children}
      </PopoverChip>
      <Chip
        label={`${label}: ${data[field]}`}
        onClick={handleClick}
        onDelete={() => {
          onDelete && onDelete();
          resetField(name);
          setAppliedFilter(name, []);
        }}
        size="medium"
      />
    </>
  );
};
