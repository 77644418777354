import LockIcon from '@mui/icons-material/Lock';
import { useFlag } from '@unleash/proxy-client-react';
import { useRecordContext } from 'react-admin';

import { ReactComponent as UnlockIcon } from '../../../../images/unlockFilledPerson.svg';
import { IconButton } from '../IconButton/IconButton';

export type UserEntityPermissionsIconButtonProps = {
  onClick: () => void;
};

export const UserEntityPermissionsIconButton: React.FC<UserEntityPermissionsIconButtonProps> = ({
  onClick,
}) => {
  const record = useRecordContext();
  const isUserEntityPermissionsEnabled = useFlag('wppa_5189_user_entity_permissions');
  if (!isUserEntityPermissionsEnabled) {
    return null;
  }
  return (
    <IconButton onClick={onClick}>
      {record?.permissionsCount ? (
        <UnlockIcon />
      ) : (
        <LockIcon sx={{ width: '20px', height: '20px' }} />
      )}
    </IconButton>
  );
};
