import { LIST_IDS_REGEX } from 'constants/regexp';

import React, { useCallback, useEffect, useState } from 'react';

import { Alert, Box, Button } from '@mui/material';
import { AppDialogActions, AppDialogBase, AppDialogHeader } from 'components/Common/Dialog';
import { useListContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { getIdsArray, getIdsString } from 'utils';
import { getIdInputValidate } from 'utils/getIdInputValidate';

import { OrdersFilterSearchByIdDialogInput } from './OrdersFilterSearchByIdDialogInput';
import { useOrdersFilterContext } from '../../context/ordersFilterContext';

type Props = {
  open: boolean;
  onClose: () => void;
  currentIdFilter: { field: string; label: string };
  resetFilters: () => void;
};

export function OrdersFilterSearchByIdDialog({
  open,
  currentIdFilter,
  onClose,
  resetFilters,
}: Props) {
  const { filterValues, setFilters, displayedFilters } = useListContext();
  const [value, setValue] = useState('');
  const form = useFormContext();
  const { notFoundOrderIds } = useOrdersFilterContext();

  const isFilterDirty = !!filterValues[currentIdFilter.field];

  const submit = useCallback(() => {
    const currentIdFilterValue = form.getValues(currentIdFilter.field);
    const currentIdFilterStringValue = getIdsString(currentIdFilterValue);

    if (!currentIdFilterStringValue.match(LIST_IDS_REGEX)) {
      form.setError(currentIdFilter.field, {
        message: 'Введите список id разделенных пробелом или переносом строки',
      });
      return;
    }

    const currentIdFilterFormattedValue = getIdsArray(currentIdFilterValue);
    setFilters(
      { ...filterValues, [currentIdFilter.field]: currentIdFilterFormattedValue },
      displayedFilters,
    );
    form.setValue(currentIdFilter.field, currentIdFilterFormattedValue);
    onClose();
  }, [value, filterValues]);

  useEffect(() => {
    if (isFilterDirty) {
      setValue(filterValues[currentIdFilter.field].join(' '));
    }
  }, []);

  return (
    <AppDialogBase onClose={onClose} open={open}>
      <AppDialogHeader
        onClose={onClose}
        sx={{ marginBottom: 4 }}
        title={`Поиск по списку ${currentIdFilter.label}`}
      />
      {notFoundOrderIds?.length ? (
        <Alert severity="warning" sx={{ marginBottom: 2 }}>
          {`${notFoundOrderIds.length} ID заказов не найдены в системе.`}
        </Alert>
      ) : (
        <Alert severity="info" sx={{ marginBottom: 2 }}>
          Укажите список заказов через пробел.
        </Alert>
      )}
      <OrdersFilterSearchByIdDialogInput
        InputLabelProps={{ shrink: true }}
        fullWidth
        label={currentIdFilter.label}
        multiline
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value);
        }}
        rows={4}
        source={currentIdFilter.field}
        validate={getIdInputValidate()}
        value={value}
        variant="outlined"
      />
      <AppDialogActions sx={{ marginTop: '12px' }}>
        <Box display="flex" gap={1}>
          <Button onClick={submit} variant="contained">
            Применить
          </Button>
          {isFilterDirty ? (
            <Button onClick={resetFilters} variant="outlined">
              Сбросить
            </Button>
          ) : (
            <Button onClick={onClose} variant="outlined">
              Отменить
            </Button>
          )}
        </Box>
      </AppDialogActions>
    </AppDialogBase>
  );
}
