import qs from 'qs';
import { Toolbar } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { AutocompleteInputIntrospected } from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { fieldWidth } from '../../themes/variables';
import { AppCreateFormSaveButton } from '../Common/Buttons';
import { AppCreateGuesser } from '../Common/Create';

type ApiClientDefaultValueType = {
  apiClient: string;
};

const ApiKeysCreate = (): JSX.Element => {
  const location = useLocation();

  const defaultValues = qs.parse(
    location?.search.replace('?', '') as string,
  ) as ApiClientDefaultValueType;

  return (
    <AppCreateGuesser
      actions={false}
      listName="Ключи API"
      title="Создание ключа API"
      toolbar={
        <Toolbar>
          <AppCreateFormSaveButton alwaysEnable />
        </Toolbar>
      }
    >
      <ReferenceInput perPage={200} reference="api_clients" source="apiClient">
        <AutocompleteInputIntrospected
          defaultValue={defaultValues.apiClient}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          label="Api клиент"
          optionText="name"
          source="name"
          sx={fieldWidth.medium}
          variant="outlined"
        />
      </ReferenceInput>
    </AppCreateGuesser>
  );
};

export default ApiKeysCreate;
