import { AppReferenceOneField } from 'components/Common';
import { NumberField, TextField } from 'react-admin';

import { usePermissions } from '../../hooks/usePermissions';
import { AppShowGuesser } from '../Common/Show';

const ApiClientWhiteIpAddressesShow = (): JSX.Element => {
  const { hasResourceAccess } = usePermissions();

  return (
    <AppShowGuesser
      actions={false}
      listName="Клиенты API – белые IP-адреса"
      title="Просмотр белого IP адреса клиента API"
    >
      <TextField label="Id" source="originId" />
      {hasResourceAccess('api_clients') && (
        <AppReferenceOneField
          label="Api клиент"
          reference="api_clients"
          source="apiClient"
          target="id"
        >
          <TextField source="name" />
        </AppReferenceOneField>
      )}
      <TextField source="ip" />
      <NumberField label="Маска" source="mask" />
    </AppShowGuesser>
  );
};

export default ApiClientWhiteIpAddressesShow;
