import React from 'react';

import { queryClient } from 'App';
import { createMerchantAccesses } from 'config/requests';
import { FormDataConsumer, useNotify } from 'react-admin';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Alert } from 'shared/mui/Alert/Alert';
import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { SaveButton } from 'shared/react-admin/Buttons';
import { AutocompleteMultiselectInput } from 'shared/react-admin/Inputs';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';
import { User } from 'types';
import { getId } from 'utils';

import {
  StyledAlertBox,
  StyledBox,
  StyledDialog,
  StyledFormBox,
} from './MerchantShowAccessCreateDialog.styled';

type Props = {
  open: boolean;
  onClose: () => void;
  merchantId: string | undefined;
};

type FormValues = {
  users: string[];
  roles: string[];
};

export const MerchantShowAccessCreateDialog: React.FC<Props> = ({ open, onClose, merchantId }) => {
  const notify = useNotify();
  const form = useForm<FormValues>();

  const { mutate } = useMutation(
    ({ merchant, users }: { merchant: number; users: number[] }) =>
      createMerchantAccesses(merchant, users),
    {
      onSuccess: () => {
        queryClient.refetchQueries(`merchants/${getId(merchantId)}/merchant_accesses`);
        onClose();
      },
      onError: (error: any) => {
        notify(error.data.errors[0].title || 'Что-то пошло не так. Повторите запрос позже.', {
          type: 'error',
        });
      },
    },
  );

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    mutate({
      merchant: Number(getId(merchantId)),
      users: values.users.map((user) => Number(getId(user))),
    });
  };

  return (
    <StyledDialog maxWidth="md" onClose={onClose} open={open}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <StyledBox>
            <CardHeader
              actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
              title="Добавить доступ"
            />
            <StyledAlertBox>
              <Alert severity="warning">
                После применения настроек доступа, выбранным пользователям будет предоставлен доступ
                к настраиваемому мерчанту в соответствии с ролями в системе.
              </Alert>
            </StyledAlertBox>
            <CardContent>
              <StyledFormBox>
                <FormDataConsumer>
                  {({ formData }) => (
                    <>
                      <ReferenceArrayInput
                        filter={{
                          name: 'плат',
                        }}
                        name="roles"
                        perPage={100}
                        reference="roles"
                        source="roleEntities"
                      >
                        <AutocompleteMultiselectInput
                          helperText={false}
                          isRequired
                          label="Роль"
                          name="roles"
                          optionText="name"
                          size="medium"
                        />
                      </ReferenceArrayInput>
                      <ReferenceArrayInput
                        filter={{ roleEntities: formData?.roles }}
                        perPage={200}
                        reference="users"
                        source="users"
                      >
                        <AutocompleteMultiselectInput
                          disabled={!formData?.roles?.length}
                          helperText={false}
                          isRequired
                          label="Пользователь"
                          name="users"
                          optionText="name"
                          renderChipLabel={(record: User) =>
                            `${record.firstName} ${record.lastName}`
                          }
                          size="medium"
                        />
                      </ReferenceArrayInput>
                    </>
                  )}
                </FormDataConsumer>
              </StyledFormBox>
            </CardContent>
            <CardActionsButtons
              leftActionsSlot={[
                <SaveButton
                  key="save-button"
                  label="Сохранить"
                  type="submit"
                  variant="contained"
                />,
                <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
              ]}
            />
          </StyledBox>
        </form>
      </FormProvider>
    </StyledDialog>
  );
};
