import { useState } from 'react';

import ContentAdd from '@mui/icons-material/Add';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { RaRecord, WithListContext } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { Button } from 'shared/mui/Buttons';
import { DeleteIconButton, EditIconButton, ShowIconButton } from 'shared/mui/IconButtons';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { BooleanField, ChipField, NumberField, TooltipTextField } from 'shared/react-admin/Fields';
import {
  DateTimeField,
  FunctionField,
  SingleFieldList,
  TextField,
} from 'shared/react-admin/Fields';
import { ListPage } from 'shared/react-admin/Pages';
import { ReferenceArrayField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { Commission, Permission } from 'types';

import { MerchantShowCommissionsCreateDialog } from './MerchantShowCommissionsCreateDialog/MerchantShowCommissionsCreateDialog';
import { MerchantCommissionsDeleteDialog } from './MerchantShowCommissionsDeleteDialog/MerchantCommissionsDeleteDialog';
import { MerchantShowCommissionsEditDialog } from './MerchantShowCommissionsEditDialog/MerchantShowCommissionsEditDialog';
import { StyledDatagrid } from './MerchantShowCommissionsList.styled';
import { MerchantShowCommissionsListFilter } from './MerchantShowCommissionsListFilter';
import { MerchantShowCommissionsShowDialog } from './MerchantShowCommissionsShowDialog/MerchantShowCommissionsShowDialog';

type Props = {
  merchantId?: string;
  merchantName?: string;
};

export const MerchantShowCommissionsList: React.FC<Props> = ({ merchantId, merchantName }) => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const {
    record: recordEditDialog,
    handleCloseRecordModal: handleCloseEditDialog,
    openRecordModal: openEditDialog,
    handleOpenRecordModal: handleOpenEditDialog,
  } = useRecordModal<Commission>();
  const {
    record: recordShowDialog,
    handleCloseRecordModal: handleCloseShowDialog,
    openRecordModal: openShowDialog,
    handleOpenRecordModal: handleOpenShowDialog,
  } = useRecordModal<Commission>();
  const {
    record: recordDeleteDialog,
    handleCloseRecordModal: handleCloseDeleteDialog,
    openRecordModal: openDeleteDialog,
    handleOpenRecordModal: handleOpenDeleteDialog,
  } = useRecordModal<Commission>();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('merchant_accounts', [Permission.Full, Permission.Create]) && (
          <Button
            key="create"
            label="Добавить"
            onClick={() => {
              setOpenCreateDialog(true);
            }}
            startIcon={<ContentAdd />}
            sx={{ marginTop: '8px', padding: '3px 16px' }}
            variant="contained"
          />
        ),
      ]}
      disableSyncWithLocation
      empty={false}
      filter={{ merchant: merchantId }}
      headerTitle={{
        titleText: 'Список комиссий',
        titleSize: 'small',
      }}
      resource="commissions"
      sort={{ field: 'id', order: 'DESC' }}
      sx={{ marginTop: 0, marginBottom: 2 }}
    >
      <WithListContext
        render={({ data, isLoading, filterValues }) => {
          const showAlert = !data?.length && !isLoading && !Object.keys(filterValues).length;
          if (showAlert) {
            return <Alert severity="info">Мерчанту {merchantName} не добавлены комиссии.</Alert>;
          }
          return (
            <>
              <MerchantShowCommissionsListFilter merchantId={merchantId} />
              <StyledDatagrid bulkActionButtons={false}>
                <TextField label="ID" sortBy="id" source="originId" />
                <TooltipTextField label="Название" source="name" />
                {hasResourceAccess('providers') && (
                  <ReferenceOneField
                    label="Провайдер"
                    reference="providers"
                    sortBy="provider.name"
                    source="provider"
                    target="id"
                  >
                    <TextField source="name" />
                  </ReferenceOneField>
                )}
                {hasResourceAccess('currencies') && (
                  <ReferenceOneField
                    label="Валюта"
                    reference="currencies"
                    sortBy="currency.alphaCode"
                    source="currency"
                    target="id"
                  >
                    <FunctionField render={(record: RaRecord | undefined) => record?.alphaCode} />
                  </ReferenceOneField>
                )}
                {hasResourceAccess('transaction_types') && (
                  <ReferenceArrayField
                    label="Типы транзакций"
                    reference="transaction_types"
                    sortable={false}
                    source="transactionTypes"
                  >
                    <SingleFieldList linkType={false}>
                      <ChipField source="name" />
                    </SingleFieldList>
                  </ReferenceArrayField>
                )}
                <BooleanField label="Нулевая комиссия" source="zeroed" />
                <NumberField emptyText="—" label="Процент" source="percent" />
                <NumberField emptyText="—" label="Фикс." source="fix" />
                <NumberField emptyText="—" label="Макс." source="max" />
                <NumberField emptyText="—" label="Мин." source="min" />
                <DateTimeField label="Дата создания" source="createdAt" />
                <DateTimeField label="Дата редактир." source="updatedAt" />
                <ActionsSlots
                  render={(record: Commission) => (
                    <>
                      {hasResourceAccess('user_actions') && (
                        <UserActionsIconButton entityName="commission" />
                      )}
                      <ShowIconButton onClick={() => handleOpenShowDialog(record)} />
                      {hasResourcePermissions('commissions', [
                        Permission.Full,
                        Permission.Update,
                      ]) && <EditIconButton onClick={() => handleOpenEditDialog(record)} />}
                      {hasResourcePermissions('commissions', [
                        Permission.Full,
                        Permission.Delete,
                      ]) && <DeleteIconButton onClick={() => handleOpenDeleteDialog(record)} />}
                    </>
                  )}
                />
              </StyledDatagrid>
            </>
          );
        }}
      />
      <MerchantShowCommissionsCreateDialog
        merchantId={merchantId}
        onClose={() => setOpenCreateDialog(false)}
        open={openCreateDialog}
      />
      <MerchantShowCommissionsEditDialog
        merchantId={merchantId}
        onClose={handleCloseEditDialog}
        onDelete={handleOpenDeleteDialog}
        open={openEditDialog}
        record={recordEditDialog}
      />
      <MerchantShowCommissionsShowDialog
        onClose={handleCloseShowDialog}
        onDelete={handleOpenDeleteDialog}
        onEdit={handleOpenEditDialog}
        open={openShowDialog}
        record={recordShowDialog}
      />
      <MerchantCommissionsDeleteDialog
        onClose={handleCloseDeleteDialog}
        open={openDeleteDialog}
        record={recordDeleteDialog}
      />
    </ListPage>
  );
};
