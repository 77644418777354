import { Close } from '@mui/icons-material';
import { styled } from '@mui/material';

export const OrdersFilterCloseIcon = styled(Close)({
  cursor: 'pointer',
  color: 'rgba(0,0,0,0.38)',
  '&:hover': {
    color: '#000',
  },
});
