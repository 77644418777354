import { useCallback, useEffect } from 'react';

import { useFlag } from '@unleash/proxy-client-react';
import { useApiContext } from 'context/apiContext';
import { usePermissions } from 'hooks/usePermissions';
import { useStore } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { CreateButton } from 'shared/mui/Buttons';
import { Divider } from 'shared/mui/Divider/Divider';
import { ListPage } from 'shared/react-admin/Pages';
import { Permission } from 'types';

import { GatewayRulesDatagrid, GatewayRulesViewToggle } from './components';
import { GatewayRulesFilters } from './GatewayRulesFilters';

const GatewayRulesList: React.FC = () => {
  const { hasResourcePermissions } = usePermissions();
  const [, setExpandAll] = useStore('gateway_rules.datagrid.view');
  const [, toggleExpandedIds] = useStore('gateway_rules.datagrid.expanded');

  const { user } = useApiContext();
  const isMerchantAccessesEnabled = useFlag('wppa-6347-merchant-accesses');

  // Функция для очистки списка раскрытых карточек при уходе со страницы.
  const resetExpandedRules = useCallback(() => {
    toggleExpandedIds([]);
    setExpandAll(false);
  }, []);

  // Очищаем список раскрытых карточек при уходе со страницы.
  useEffect(() => resetExpandedRules, []);

  return (
    <ListPage
      actions={[
        hasResourcePermissions('gateway_rules', [Permission.Full, Permission.Create]) && (
          <CreateButton key="create-rules" />
        ),
        <Divider flexItem key="divider" orientation="vertical" sx={{ marginX: 2 }} />,
        <GatewayRulesViewToggle key="gateway_rules_view_toggle" />,
      ]}
      empty={false}
      headerTitle={{ titleText: 'Правила' }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <GatewayRulesFilters resetExpandedRules={resetExpandedRules} />
      {isMerchantAccessesEnabled && user.showAlertEmptyAccess ? (
        <Alert severity="warning">{`Для пользователя ${user.firstName} ${user.lastName} нет доступных мерчантов, обратитесь к администратору системы настроек доступа`}</Alert>
      ) : (
        <GatewayRulesDatagrid resetExpandedRules={resetExpandedRules} />
      )}
    </ListPage>
  );
};

export default GatewayRulesList;
