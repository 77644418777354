import { Card, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { ClickAwayListener } from 'shared/mui/ClickAwayListener/ClickAwayListener';
import { TextFieldWithCopyButton } from 'shared/mui/Fields';
import { CloseIconButton } from 'shared/mui/IconButtons';

type NotificationKeyTooltipCardProps = {
  value?: string;
  onClose: () => void;
};

export const NotificationKeyTooltipCard: React.FC<NotificationKeyTooltipCardProps> = ({
  value,
  onClose,
}) => (
  <ClickAwayListener onClickAway={onClose}>
    <Card
      sx={{
        width: '444px',
      }}
    >
      <CardHeader
        actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
        title="Ключ нотификации"
      />
      <CardContent sx={{ paddingTop: '0!important' }}>
        <TextFieldWithCopyButton
          disabled
          fullWidth
          size="medium"
          sx={{ marginTop: 0 }}
          value={value}
          variant="outlined"
        />
      </CardContent>
    </Card>
  </ClickAwayListener>
);
