import {
  EXPORT_FORMAT_NAMES,
  ExportFormats,
  ExportJobTypes,
  ExportTransportTypes,
  ReportNames,
} from 'constants/exportFormats';

import { useState } from 'react';

import { Alert, Box } from '@mui/material';
import { getUserInfo } from 'config/requests';
import { useReportExport } from 'hooks/queries/useReportExport';
import { useGetExistingIdFilterName } from 'hooks/useGetExistingIdFilterName';
import { useGetNotFoundOrderIds } from 'hooks/useGetNotFoundOrderIds';
import { useListController, SortPayload, WithListContext, useNotify } from 'react-admin';
import { useQuery } from 'react-query';
import { NotifyDialog } from 'shared/mui/Dialogs';
import { Typography } from 'shared/mui/Typography';
import { ExportEditButton } from 'shared/react-admin/Export/ExportEditButton/ExportEditButton';
import { ReportExportDialogMessage } from 'shared/widgets/ReportExport/ReportExportDialogMessage';
import { ExportFormat, Order, OrderDispute } from 'types';

import { OrdersListLocalDatagrid } from './OrdersListLocalDatagrid';
import { AppList } from '../../Common/List';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../../Common/PanelHeader';
import OrdersDisputeCreateDialog from '../components/OrderDispute/OrdersDisputeCreateDialog';
import OrdersDisputeEditDialog from '../components/OrderDispute/OrdersDisputeEditDialog';
import { EXPORT_FIELDS_ORDERS } from '../constants/exportFields';
import { OrdersFilterContextProvider } from '../context/ordersFilterContext';
import { OrdersListActions } from '../index';
import { OrdersFilter } from '../OrdersFilter/OrdersFilter';

export const OrdersList = (): JSX.Element => {
  const { filterValues } = useListController({ sort: {} as SortPayload });
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const [selectedDispute, setSelectedDispute] = useState<OrderDispute | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNotifyDialog, setIsOpenNotifyDialog] = useState(false);
  const [isSendReportOnEmail, setIsSendReportOnEmail] = useState(false);
  const [exportFormat, setExportFormat] = useState<ExportFormat>(ExportFormats.Excel);
  const notify = useNotify();

  const { mutate: exportMutate, isLoading: isExportLoading } = useReportExport({
    filters: {
      ...filterValues,
      exportType: exportFormat,
      jobType: ExportJobTypes.Orders,
      ...(isSendReportOnEmail && { notificationTransport: [ExportTransportTypes.Email] }),
    },
    preferenceKey: ReportNames.Orders,
    onSuccess: () => {
      notify(
        `Файл экспорта заказов формируется ${isSendReportOnEmail ? 'и будет отправлен вам на почту' : ''}`,
        {
          type: 'info',
        },
      );
      setIsOpenNotifyDialog(false);
    },
    onError: (error) => {
      notify(error.data?.errors[0]?.title, { type: 'error' });
    },
  });

  const { data: user } = useQuery('me', getUserInfo);

  return (
    <AppList
      empty={false}
      pagination={<></>}
      queryOptions={{ enabled: Object.keys(filterValues).length > 0 }}
      sort={{} as SortPayload}
    >
      <WithListContext
        render={({ isLoading, data: orders, total }) => {
          const notFoundOrderIds = useGetNotFoundOrderIds(orders as Order[], filterValues);
          const existingIdFilterName = useGetExistingIdFilterName(filterValues);

          return (
            <OrdersFilterContextProvider value={{ notFoundOrderIds }}>
              {selectedDispute ? (
                <OrdersDisputeEditDialog
                  dispute={selectedDispute}
                  onClose={() => setIsOpen(false)}
                  open={isOpen}
                  order={selectedOrder}
                />
              ) : (
                <OrdersDisputeCreateDialog
                  onClose={() => setIsOpen(false)}
                  open={isOpen}
                  order={selectedOrder}
                />
              )}
              <AppPanelHeader>
                <AppPanelHeaderToolbar
                  actions={[
                    <OrdersListActions
                      exportFormat={exportFormat}
                      key="orders_actions"
                      onExportFormatsButtonClick={() => setIsOpenNotifyDialog(true)}
                      setExportFormat={setExportFormat}
                    />,
                  ]}
                  titleText="Заказы"
                />
              </AppPanelHeader>
              <OrdersFilter
                alert={
                  (total || 0) >= 1001 ? (
                    <Alert severity="warning" sx={{ margin: '15px 0 10px 0' }}>
                      Применённый фильтр слишком широкий, поэтому показано только 1000 записей.
                      Чтобы получить все результаты фильтрации, сделайте экспорт.
                    </Alert>
                  ) : notFoundOrderIds.length && !isLoading ? (
                    <Alert severity="warning" sx={{ margin: '15px 0 10px 0' }}>
                      {`Заказы с ID: ${notFoundOrderIds.join(
                        ' ',
                      )} в результате поиска по ${existingIdFilterName} не найдены в системе.`}
                    </Alert>
                  ) : undefined
                }
              />
              <Box
                sx={{
                  minHeight: '100%',
                  display: 'initial',
                }}
              >
                <OrdersListLocalDatagrid
                  isLoading={isLoading}
                  orders={(orders as Order[]) || []}
                  setIsOpenDispute={setIsOpen}
                  setSelectedDispute={setSelectedDispute}
                  setSelectedOrder={setSelectedOrder}
                  total={total || 0}
                />
                <NotifyDialog
                  isLoading={isExportLoading}
                  message={
                    <ReportExportDialogMessage
                      exportFormat={EXPORT_FORMAT_NAMES[exportFormat]}
                      isSendReportOnEmail={isSendReportOnEmail}
                      onChange={() => setIsSendReportOnEmail((prev) => !prev)}
                      userEmail={user?.corporateEmail || user?.email || 'почта неизвестна'}
                    />
                  }
                  onClose={() => setIsOpenNotifyDialog(false)}
                  onSubmit={exportMutate}
                  open={isOpenNotifyDialog}
                  title={
                    <>
                      <Typography
                        component="span"
                        sx={{ verticalAlign: 'middle' }}
                        variant="inherit"
                      >
                        Экспорт отчета
                      </Typography>
                      <ExportEditButton
                        fields={EXPORT_FIELDS_ORDERS}
                        preferenceKey={ReportNames.Orders}
                      />
                    </>
                  }
                />
              </Box>
            </OrdersFilterContextProvider>
          );
        }}
      />
    </AppList>
  );
};
